import React, { Fragment, ReactElement, ReactNode, useRef, useState } from 'react'
import {
	Card, CardBody, CardHeader,
	DropdownItem, Panel, PanelHeader, PanelMain,
	Tooltip
} from '@patternfly/react-core'
import ArrowIcon from '@patternfly/react-icons/dist/esm/icons/arrow-icon'

type ConfirmButtonProps =
	{
		key?: string|number
		prompt?: string,
		desc?: string,
		children?: ReactNode,
		controls?: ReactElement<any>,
		onResult? :(confirmed :boolean) => any
		onConfirm? :Function
		onReject? :Function
		className? :string
		icon?: ReactNode
	}

export function ConfirmButton(props : ConfirmButtonProps)
{
	const key = props.key
	const children = props.children
	let controls = props.controls
	const prompt = props.prompt
	const desc = props.desc
	const className = props.className
	const icon = props.icon
	
	const ref = useRef(null)
	const [open, setOpen] = useState<boolean>(false)
	
	const resultHandler = (confirmed :boolean) =>
	{
		setOpen(false)
		
		if(props.onResult)
			props.onResult(confirmed)
		
		if(confirmed && props.onConfirm)
			props.onConfirm()
	}
	
	if(!controls)
		controls = <>
			<button className="btn btn-success"
					onClick={() => resultHandler(true)}>
				Confirm
			</button>{' '}
			<button className="btn btn-danger"
					onClick={() => resultHandler(false)}>
				Cancel
			</button>
		</>
	
	// ms-sm-auto
	return (
		<>
			<Tooltip
				isVisible={open}
				trigger='click'
				content={
					<Card isPlain>
						<CardHeader>
							<h6>{prompt || (desc && `Are you sure you want to ${desc}?`) || "Are you sure?"}</h6>
						</CardHeader>
						<CardBody>
							{controls}
						</CardBody>
					</Card>
				}
				entryDelay={1}
				position={'left'}
				enableFlip={true}
			>
				<button ref={ref} className={className} onClick={() => setOpen(!open)}>
					{icon}{icon && ' '}{children}
				</button>
			</Tooltip>
		</>
	)
}
