import { observable, computed, runInAction, toJS, makeObservable } from 'mobx'
import _compact from 'lodash/compact'
import _isArray from 'lodash/isArray'
import _isObject from 'lodash/isObject'
import _isString from 'lodash/isString'
import _each from 'lodash/each'
// -----------------------------------
import { varType, isElement, DATA_FORM_STATE_ELEMENT } from '../types'
// -----------------------------------
import { required } from '../validators'
import { DataSchemaElement } from '../Schema/Element'
import { FieldValidationState } from '../ValidationContext'
import { invariant } from '../../invariant'

export class FormFieldState
{
	schema? :DataSchemaElement
	
	key :string = ''
	name :string = ''
	instance :string = ''
	
	// Observable --------------
	focused = false
	touched = false
	visited = false
	message = ''
	disabled :boolean = false
	valid = true
	validated = false
	
	initial = ''
	value = ''
	// -------------------------
	
	get json()
	{
		return toJS(this.value)
	}
	
	get checked()
	{
		return Boolean(this.value)
	}
	
	get modified()
	{
		return this.value !== this.initial
	}
	
	onFocus = (e :any) =>
	{
		runInAction(() =>
		{
			this.focused = true
			this.touched = true
		})
	}
	
	onBlur = (e :any) =>
	{
		runInAction(() =>
		{
			this.focused = false
			this.visited = true
			// async
			this.validate(true)
		})
	}
	
	onChange = (...args :Array<any>) =>
	{
		let realValue :any
		
		if(args.length == 2)
			// @ts-ignore
			realValue = getValue(args[1])
		else
			realValue = getValue(args[0])
		
		runInAction(() =>
		{
			this.value = realValue
			// async
			this.validate()
		})
	}
	
	props()
	{
		return {
			id: this.key,
			disabled: this.disabled,
			onFocus: this.onFocus,
			onBlur: this.onBlur,
			onChange: this.onChange,
		}
	}
	
	// ---
	
	constructor(schema :DataSchemaElement, value :string = '', instance :string = '')
	{
		//@ts-ignore
		this[DATA_FORM_STATE_ELEMENT] = true
		
		makeObservable(this, {
			focused: observable,
			touched: observable,
			visited: observable,
			message: observable,
			disabled: observable,
			value: observable,
			initial: observable,
			validated: observable,
			valid: observable,
		})
	
		invariant(isElement(schema),
			'schema must be a Schema Element. Got ' + varType(schema))
		
		this.schema = schema
		
		this.key = String(schema && _compact([schema.path, schema.name, instance]).join('.'))
		this.name = String(schema && schema.name)
		this.disabled = schema && schema.disabled
		this.instance = instance
		
		this.initial = value
		this.value = value
		
		this.onFocus = this.onFocus.bind(this)
		this.onBlur = this.onBlur.bind(this)
		this.onChange = this.onChange.bind(this)
	}
	
	dispose()
	{
		delete this.schema
	}
	
	set(value :any)
	{
		runInAction(() =>
		{
			this.value = value
			this.initial = value
		})
	}
	
	clean()
	{
		this.initial = this.value
		
		return this
	}
	
	reset()
	{
		this.value = this.initial
		
		return this
	}
	
	async getValidationState(force = false) :Promise<FieldValidationState>
	{
		if(force)
			runInAction(() =>
				this.visited = true)
		
		let state :FieldValidationState = {el: this, name: this.name || '', valid: true, message: '', errors: []}
		
		if(this.schema)
		{
			// If element is required, first validate that, then any assigned validator
			if(this.schema.required)
			{
				state = await required(this, state)
				return (this.schema && this.schema.validator
					? this.schema.validator(this, state)
					: state)
			}
			// Field is not required; If we have an assigned validator, validate with that
			else if(this.schema.validator)
				return this.schema.validator(this, state)
		}
		
		// Field is not required and there is no validator, resolve with initial state
		return state
	}
	
	async validate(force = false)
	{
		// console.info('validating', this.name);
		
		const state = await this.getValidationState(force)
		
		runInAction(() =>
		{
			// console.warn('validateElement', result);
			
			// Validators may return an array of states, in which case we need to dispatch each one
			// (currently only for the password validators, but potentially for any grouped validation)
			if(_isArray(state))
			{
				_each(state, (state :FieldValidationState) =>
				{
					if(_isObject(state))
					{
						state.el.validated = true
						state.el.valid = state.valid
						state.el.message = state.message
						
						//console.warn(result.el.name, 'valid?', result.el.valid);
					}
				})
			}
			else if(_isObject(state))
			{
				state.el.validated = true
				state.el.valid = state.valid
				state.el.message = state.message
				
				//console.warn(result.el.name, 'valid?', result.el.valid);
			}
			
			// console.warn('complete', this);
			
			this.valid = !!state.valid
			this.message = state.message
		})
		
		return {
			errors: state.errors,
			message: state.message,
			name: state.name,
			valid: state.valid,
		}
	}
}

// ---

function isEvent(candidate :any)
{
	return !!(candidate && candidate.stopPropagation && candidate.preventDefault)
}

function getValue(e :any) :string
{
	// console.info('getValue', e)
	
	if(isEvent(e))
	{
		const {
			target: {type, value, checked},
		} = e
		
		return type === 'checkbox' ? checked : value
	}
	
	// not an event, so must be either our value or an object containing our
	// value in the 'value' key
	return e && typeof e === 'object' && e.value !== undefined ? e.value : e // extract value from { value: value } structure.
	// https://github.com/nikgraf/belle/issues/58
}
