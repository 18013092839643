import { FormFieldState } from '../../../library/rathe-strongly-abstract/Form/State/Element'
import { observer } from 'mobx-react'
import cls from 'classnames'
import {
	FormFeedback, FormGroup,
	Input as ReactStrapInput,
	InputGroup,
	InputGroupText,
	Label as ReactStrapLabel
} from 'reactstrap'
import { Dropdown, DropdownToggle, DropdownItem, DropdownSeparator } from '@patternfly/react-core'
import { SelectSchemaOptionsType } from '../../../store/schema'
import { InputType } from 'reactstrap/types/lib/Input'
import { Fragment, useState } from 'react'
import { capitalize, isFunction } from 'lodash'

export interface SchemaFieldProps
{
	state?: FormFieldState,
	placeholder?:string,
	className?:string
	label?:string|(() => string)
}

const fieldState = (state: FormFieldState) => ({
	valid: state.visited && state.validated && state.valid,
	invalid: state.visited && state.validated && !state.valid
})

export const SchemaInput = observer((props :SchemaFieldProps) =>
{
	//console.warn('Input render')
	const { state, placeholder, className, label } = props
	
	// Even though state should always be set, don't create errors
	if(!state || !state.schema)
		return null
	
	if(state.schema.type === 'domain')
		return <SchemaDomain {...props} />
	
	if(state.schema.type === 'switch')
		return <SchemaSwitch {...props} />

	//const options = state.schema.options as SelectSchemaOptionsType
	
	//const valid = !state.visited
	//	? undefined
	//	: state.validated && state.valid
	
	const valid = state.visited && state.validated && state.valid
	const invalid = state.visited && state.validated && !state.valid
	
	//const invalid = !state.visited
	//	? undefined
	//	: state.validated && !state.valid
	
	//@ts-ignore
	const type :InputType = state.schema.type || 'text'

	return (
		<ReactStrapInput
			{...state.props()}
			{...fieldState(state)}
			type={type}
			className={cls(className, {error: !state.valid})}
			placeholder={placeholder || (state.schema.placeholder)}
			value={state.value}
			disabled={state.disabled}
		/>
	)
})

export const SchemaLabel = observer(({state, placeholder, className} :SchemaFieldProps) =>
{
	if(!state || !state.schema)
		return null
	
	return state.schema && state.schema.label
		?
		<ReactStrapLabel htmlFor={state.key}>
			{state.schema.label}
		</ReactStrapLabel>
		: null
})

export const SchemaError = observer(({state, placeholder, className} :SchemaFieldProps) =>
{
	if(!state || !state.schema)
		return null
	
	return !state.valid
		?
		<FormFeedback valid={false} style={{display:'block'}}>
			{state.message}
		</FormFeedback>
		: null
})

// ---

export const SchemaSwitch = observer(({state, placeholder, className, label} :SchemaFieldProps) =>
{
	if(!state || !state.schema)
		return null
	
	//console.warn('render SchemaSwitch', label)
	
	if(label instanceof Function)
		label = label()
	
	console.info('label', label)
	
	return state.schema
		?
			<FormGroup switch>
				<ReactStrapLabel htmlFor={state.key} check>
					{label ? label : state.schema.label}
				</ReactStrapLabel>
				<ReactStrapInput
					{...state.props()}
					{...fieldState(state)}
					type="switch"
					role="switch"
					placeholder={placeholder || (state.schema.placeholder)}
					checked={!!state.value}
					disabled={state.disabled}
				/>
			</FormGroup>
		: null
})


export const SchemaSelectStatus = observer(({state, placeholder, className, label} :SchemaFieldProps) =>
{
	const [isOpen, setIsOpen] = useState(false)
	
	if(!state || !state.schema)
		return null
	
	//console.warn('render SchemaSwitch', label)
	
	if(label instanceof Function)
		label = label()
	
	console.info('label', label)
	
	const onToggle = (isOpen :boolean) =>
	{
		setIsOpen(isOpen)
	}
	
	const onFocus = () =>
	{
		const element = document.getElementById('toggle-initial-selection')
		
		if(element)
			element.focus()
	}
	
	const onSelect = () =>
	{
		setIsOpen(false)
		onFocus()
	}
	
	let dropdownItems :Array<JSX.Element> = []
	
	console.warn('state.schema.options', state.schema.options)
	
	let currentTitle = capitalize(state.value)
	let currentIcon = null
	let currentClassName = ''
	let currentTooltip = undefined
	
	if(state.schema.options)
		for(let o of state.schema.options)
		{
			if(o.key === state.value)
			{
				currentTitle = o.title
				currentIcon = o.icon
				currentClassName = cls(o.className, o.key)
				currentTooltip = o.tooltip
			}
			else
				dropdownItems.push(
					<DropdownItem
						key={o.key}
						data-value={o.key}
						tooltip={o.tooltip}
						tooltipProps={{position: 'right'}}
						onClick={() => state?.onChange(o.key)}
						icon={o.icon}
						component="button"
						className={cls(o.className, o.key)}
					>
						{o.title}
					</DropdownItem>
				)
		}

	//<DropdownItem key="link">Link</DropdownItem>,
	//<DropdownItem key="action" component="button" autoFocus> Action </DropdownItem>,
	//<DropdownItem key="disabled link" isDisabled href="www.google.com"> Disabled link </DropdownItem>,
	//<DropdownItem
	//	key="disabled action"
	//	isAriaDisabled
	//	component="button"
	//	tooltip="Tooltip for disabled item"
	//	tooltipProps={{position: 'top'}}
	//> Disabled action </DropdownItem>,
	//<DropdownSeparator key="separator" />,
	//<DropdownItem key="separated link">Separated link</DropdownItem>,
	//<DropdownItem key="separated action" component="button"> Separated action </DropdownItem>
	
	return state.schema
		?
			<Dropdown
				className={className}
				onSelect={onSelect}
				toggle={
					<DropdownToggle
						className={currentClassName}
						onToggle={onToggle}
					>
						{currentIcon} {currentTitle}
					</DropdownToggle>
				}
				isOpen={isOpen}
				dropdownItems={dropdownItems}
			/>
		: null
})

export const SchemaDomain = observer(({state, placeholder, className} :SchemaFieldProps) =>
{
	if(!state || !state.schema)
		return null
	
	return state.schema && state.schema.label
		?
			<InputGroup>
				<InputGroupText>
					https://
				</InputGroupText>
				<ReactStrapInput
					{...state.props()}
					{...fieldState(state)}
					type='text'
					className={cls(className, {error: !state.valid})}
					placeholder={placeholder || (state.schema.placeholder)}
					value={state.value}
					disabled={state.disabled}
				/>
				<InputGroupText>
					/
				</InputGroupText>
			</InputGroup>
		: null
})

// ---

export const SchemaField =
	{
		SchemaInput,
		SchemaLabel,
		SchemaMessage: SchemaError
	}
