import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../store'
import {
	apiRequestCreateProxy, apiRequestDeleteProxy,
	apiRequestProxies,
	apiRequestProxyItem,
	apiRequestUpdateProxy
} from '../../api/actions/proxy'
import { ProxyConfig, ProxyConfigEntity, ProxyConfigMapping } from './types'
import { UserStateType } from '../user'
import { endLoading, startLoading } from '../view'
import { initProxies, setNewProxy } from '../proxy'

export async function createNewProxyConfig(dispatch :AppDispatch, user :UserStateType, proxy :ProxyConfig) :Promise<any>
{
	dispatch({type: 'proxy/createSingleProxyConfig'})
	dispatch(startLoading('Loading entities'))
	
	return apiRequestCreateProxy(user, proxy)
		.then(proxies =>
			dispatch(setNewProxy(proxies)))
		.finally(() =>
			dispatch(endLoading()))
}

export async function fetchProxyConfigs(dispatch :AppDispatch, user :UserStateType) :Promise<any>
{
	dispatch({type: 'proxy/fetchProxyConfigs'})
	dispatch(startLoading('Loading entities'))
	
	return apiRequestProxies(user)
		.then(proxies =>
			dispatch(initProxies(proxies)))
		.finally(() =>
			dispatch(endLoading()))
}

export async function fetchProxyConfigById(dispatch :AppDispatch, user :UserStateType, id: string) :Promise<any>
{
	dispatch({type: 'proxy/fetchProxyConfigs'})
	dispatch(startLoading('Loading entities'))
	
	return apiRequestProxyItem(user, id)
		.then(proxy =>
			dispatch(setNewProxy(proxy)))
		.finally(() =>
			dispatch(endLoading()))
}

export async function updateProxyConfig(dispatch :AppDispatch, user :UserStateType, proxy :ProxyConfig) :Promise<ProxyConfig>
{
	dispatch({type: 'proxy/createSingleProxyConfig'})
	dispatch(startLoading('Loading entities'))
	
	return apiRequestUpdateProxy(user, proxy)
		.then(proxy =>
		{
			dispatch(setNewProxy(proxy))
			return proxy
		})
		.finally(() =>
			dispatch(endLoading()))
}

export async function deleteProxyConfig(dispatch :AppDispatch, user :UserStateType, proxy :ProxyConfigEntity) :Promise<any>
{
	dispatch({type: 'proxy/deleteSingleProxyConfig'})
	dispatch(startLoading('Loading entities'))
	
	return apiRequestDeleteProxy(user, proxy.id)
		.then(proxy =>
			dispatch(setNewProxy(proxy)))
		.finally(() =>
			dispatch(endLoading()))
}
