import { hash } from '../../utils'

export const SERVER_STATUS_IDLE = 'idle'
export const SERVER_STATUS_PROCESSING = 'processing'

export interface ProcessEventEntity
{
	id :number|string
	started :number
	ended? :number
	command :string
	log? :string
	errlog? :string
	result?: string
}

export type ProcessEventEntityList = Array<ProcessEventEntity>

