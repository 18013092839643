import { useNavigate, Link, useLocation } from 'react-router-dom'
// ---
import { useAppDispatch, useAppSelector } from '../../../store/store'
import {
	CardTitle, CardBody, CardText,
	FormGroup, FormText, Form,
	Label, Input,
	Row, Col, CardHeader, Card, CardFooter, Table
} from 'reactstrap'
import { selectUserState } from '../../../store/user'
import { useState } from 'react'
import {
	ProxyConfigEntity,
	ProxyConfigMappingEntity, selectProxyMappingsByDomain,
} from '../../../store/proxy'
import {
	DataListAction,
	DataListCell,
	DataListContent,
	DataListItem,
	DataListItemCells,
	DataListItemRow,
	DataListToggle,
	Dropdown,
	DropdownItem,
	DropdownPosition,
	DropdownSeparator,
	DropdownToggle,
	DropdownToggleAction,
	KebabToggle
} from '@patternfly/react-core'
import HomeIcon from '@patternfly/react-icons/dist/esm/icons/home-icon'
import DomainIcon from '@patternfly/react-icons/dist/esm/icons/domain-icon'
import RouteIcon from '@patternfly/react-icons/dist/esm/icons/route-icon'
import ArrowIcon from '@patternfly/react-icons/dist/esm/icons/arrow-icon'
import TrashIcon from '@patternfly/react-icons/dist/esm/icons/trash-icon'
import { commandProcessSingle } from '../../../store/proxy/command'
import { ProxyListItemDetails } from './ProxyListItemDetails'
import { ConfirmDropdownItem } from '../ConfirmDropdownItem'
import { deleteProxyConfig } from '../../../store/proxy/crud'

export function ProxyListItem({proxy} :{proxy :ProxyConfigEntity})
{
	const {
		rootPath,
		mappings,
		showDetails,
		setShowDetails,
		showMenu,
		setShowMenu,
		onEdit,
		onApply,
		onDelete
	} = useProxyListItemLocals(proxy)
	
	return (
		<DataListItem aria-labelledby="ex-item1" isExpanded={showDetails}>
			<DataListItemRow>
				<DataListToggle
					onClick={() => setShowDetails(!showDetails)}
					isExpanded={showDetails}
					id="ex-toggle1"
					aria-controls="ex-expand1"
				/>
				<DataListItemCells
					dataListCells={[
						<DataListCell isIcon key="icon">
							<Link className="link-primary" to={`/proxy/${proxy.domain}`}>
								<RouteIcon />
							</Link>
						</DataListCell>,
						<DataListCell key="domain">
							<h5>
								<Link className="text-decoration-none link-dark" to={`/proxy/${proxy.domain}`}>{proxy.name}</Link>
							</h5>
							<div>
								<a href={`https://${proxy.domain}`} target={proxy.id} className="text-medium-dark">
									<ArrowIcon /> https://{proxy.domain}
								</a>
							</div>
						</DataListCell>,
						<DataListCell key="url">
							<div>
								<code>{proxy.domain}</code>
							</div>
						</DataListCell>
					]}
				/>
				<DataListAction
					aria-labelledby="ex-item1 ex-action1"
					id="ex-action1"
					aria-label="Actions"
					isPlainButtonAction
				>
					<Dropdown
						//onSelect={onEdit}
						position={DropdownPosition.right}
						isOpen={showMenu}
						toggle={
							<DropdownToggle
								splitButtonItems={[
									<DropdownToggleAction key="action" onClick={onEdit}>
										Edit
									</DropdownToggleAction>
								]}
								splitButtonVariant="action"
								onToggle={() => setShowMenu(!showMenu)}
							/>
						}
						dropdownItems={[
							<DropdownItem
								key="apply"
								icon={<ArrowIcon />}
								onClick={onApply}
								description="Apply this configuration to the server"
								className="text-decoration-none"
							>
								Apply
							</DropdownItem>,
							<DropdownSeparator key="separator" />,
							<ConfirmDropdownItem
								key="delete"
								icon={<TrashIcon />}
								onConfirm={onDelete}
								description="Deactivate and archive this configuration"
							>
								Delete
							</ConfirmDropdownItem>
						]}
					/>
				</DataListAction>
			</DataListItemRow>
			<DataListContent
				aria-label="Proxy details"
				id="ex-expand1"
				isHidden={!showDetails}
			>
				<ProxyListItemDetails  proxy={proxy} />
			</DataListContent>
		</DataListItem>
	)
}

function useProxyListItemLocals(proxy :ProxyConfigEntity)
{
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	
	let user = useAppSelector(selectUserState)
	
	let initialState :ProxyConfigMappingEntity|undefined = undefined
	const [ rootMapping, setRootMapping ] = useState(initialState)
	
	let mappings = useAppSelector(selectProxyMappingsByDomain(proxy.domain))
	
	const [showDetails, setShowDetails] = useState(false)
	const [showMenu, setShowMenu] = useState(false)
	
	if(!rootMapping)
		for(let m of mappings)
		{
			if(m.from === '/')
			{
				//@ts-ignore
				setRootMapping(m)
				break
			}
		}
	
	const onEdit = async (e : any) =>
	{
		e && e.preventDefault()
		
		if(proxy)
			navigate(`/proxy/${proxy.domain}`)
	}
	
	const onApply = async (e : any) =>
	{
		e && e.preventDefault()
		
		if(proxy)
		{
			await commandProcessSingle(dispatch, user, proxy.id!)
			navigate('/remote')
		}
	}
	
	const onDelete = async (e : any) =>
	{
		e && e.preventDefault()
		
		if(proxy)
		{
			await deleteProxyConfig(dispatch, user, proxy)
			navigate('/remote')
		}
	}
	
	return {
		rootPath: rootMapping,
		mappings,
		navigate,
		dispatch,
		showDetails,
		setShowDetails,
		showMenu,
		setShowMenu,
		onEdit,
		onApply,
		onDelete
	}
}
