import _isString from 'lodash/isString'
import { FormFieldState } from '../State/Element'
import { FieldValidationState } from '../ValidationContext'

var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export async function email(el :FormFieldState, state :FieldValidationState)
{
	//console.info('validating email', `Name '${el.name}'`, `Value '${el.value}'`, state);

	if (!el.visited)
		return state

	if (!re.test(el.value))
	{
		console.warn('email test failed')

		state.valid = false
		state.message = (!el.value || el.value.length < 1) && el.schema && el.schema.required
			? _isString(el.schema.required)
				? el.schema.label + ' is required'
				: 'This field is required'
			: 'Invalid email address'
		state.errors.push({message: 'Invalid email address'})
	}

	return state
}
