import { Fragment, useEffect, useState } from 'react'
// ---
import { Header } from './components/Header'
import { Layout } from './components/Layout'
import { Loading } from './components/Loading'
import { NotificationList } from './components/Notification'
import { EditProxyComponent } from './components/EditProxy'

export function EditProxyPage()
{
	return (
		<Fragment>
			<Header />
			<NotificationList />
			<Layout>
				<EditProxyComponent />
			</Layout>
			<Loading />
		</Fragment>
	)
}

