import { createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { NavStateDataType } from '../dom/history'
import { notificationAdapter } from './view/adapter'
import { normalizeNotification, Notification, NotificationEntity } from './view/types'

export type ViewStateType = {
	visible : boolean
	pathname : string
	path_vars: any,
	loading: boolean
	loading_message: string
	error_message: string,
	notifications: EntityState<object>
}

export const initialState = {
	visible: true,
	pathname: '/',
	path_vars: {},
	loading: false,
	loading_message: '',
	error_message: '',
	notifications: notificationAdapter.getInitialState()
}

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes

const viewSlice = createSlice(
	{
		name: 'view',
		initialState,
		reducers: {
			appVisibilityChange: (state, action) =>
            {
				const visible = action.payload as boolean
				state.visible = visible
			},
			navStateChange: (state, action) =>
            {
				const nav_state = action.payload as NavStateDataType
				state.pathname = nav_state.location.pathname
			},
			startLoading: (state, action?) =>
            {
				state.loading = true
				state.loading_message = action.payload as string
				state.error_message = ''
			},
			loadingError: (state, action :PayloadAction<string|Error>) =>
            {
				state.loading = false
				if(action.payload instanceof Error)
					state.error_message = action.payload.message
				else
					state.error_message = String(action.payload)
			},
			endLoading: (state) =>
            {
				state.loading = false
				state.loading_message = ''
				state.error_message = ''
			},
			
			notification: (state, action :PayloadAction<Notification>) =>
            {
				const entity = normalizeNotification(action.payload)
				notificationAdapter.addOne(state.notifications, entity)
			},
			notificationExpired: (state, action :PayloadAction<NotificationEntity>) =>
            {
				notificationAdapter.removeOne(state.notifications, action.payload.id)
			},
		},
	})

// Action creators are generated for each case reducer function
export const { appVisibilityChange, navStateChange, startLoading, loadingError, endLoading, notification, notificationExpired } = viewSlice.actions
export const viewReducer = viewSlice.reducer
export const selectViewState = (state: RootState) => state.view
export const selectViewNotifications = (state: RootState) => state.view.notifications

const notificationSelectors = notificationAdapter.getSelectors<RootState>(selectViewNotifications)

export const selectAllNotifications = (state :RootState) =>
	notificationSelectors.selectAll(state)

export const selectNotificationById = (id :string) =>
	(state :RootState) : NotificationEntity|undefined =>
		notificationSelectors.selectById(state, id)

