import { createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { remoteEventAdapter } from './remote/adapter'
import { NotificationEntity } from './view/types'
import { ProcessEventEntity, SERVER_STATUS_IDLE } from './remote/types'
import { notificationAdapter } from './view/adapter'

export type RemoteStateType = {
	status :string
	active :boolean
	events: EntityState<object>
}

export const initialState = {
	status: SERVER_STATUS_IDLE,
	active: false,
	events: remoteEventAdapter.getInitialState()
}

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes

const remoteSlice = createSlice(
	{
		name: 'remote',
		initialState,
		reducers: {
			statusChanged: (state, action) =>
            {
				console.warn('statusEvent', action.payload)
				state.status = action.payload
				state.active = state.status !== SERVER_STATUS_IDLE
			},
			eventBegin: (state, action :PayloadAction<ProcessEventEntity>) =>
            {
				console.warn('eventBegin', action.payload)
				const entity = action.payload
				entity.log = ''
				entity.errlog = ''
				remoteEventAdapter.addOne(state.events, entity)
			},
			eventLog: (state, action :PayloadAction<{id :string, text :string}>) =>
            {
				console.warn('eventLog', action.payload)
				const {id, text} = action.payload
				const entity = state.events.entities[id]
				
				if(!entity)
					throw new Error(`eventLog entity ${id} does not exist`)
				
				entity.log += text
			},
			eventErrLog: (state, action :PayloadAction<{id :string, text :string}>) =>
            {
				console.warn('eventErrLog', action.payload)
				const {id, text} = action.payload
				const entity = state.events.entities[id]
				
				if(!entity)
					throw new Error(`eventErrLog entity ${id} does not exist`)
				
				entity.errlog += text
			},
			eventEnd: (state, action :PayloadAction<{id :string, ended :number, code :number, text :string}>) =>
            {
				console.warn('eventEnd', action.payload)
				const {id, ended, code, text} = action.payload
				const entity = state.events.entities[id]
				
				if(!entity)
					throw new Error(`eventErrLog entity ${id} does not exist`)
				
				entity.ended = ended
				entity.log = text
				entity.result = `${code}`
			},
		},
	})

export const { statusChanged, eventBegin, eventLog, eventErrLog, eventEnd } = remoteSlice.actions
export const remoteReducer = remoteSlice.reducer
export const selectRemoteState = (state: RootState) => state.remote
export const selectRemoteEvents = (state: RootState) => state.remote.events

const remoteEventSelectors = remoteEventAdapter.getSelectors<RootState>(selectRemoteEvents)

export const selectAllEvents = (state :RootState) =>
	remoteEventSelectors.selectAll(state)

export const selectById = (id :string) =>
	(state :RootState) : ProcessEventEntity|undefined =>
		remoteEventSelectors.selectById(state, id)

export const selectLatestEvent = () =>
	(state :RootState) : ProcessEventEntity|undefined =>
	{
		const ids = state.remote.events.ids
		const lastId = ids[ids.length-1]
		return remoteEventSelectors.selectById(state, lastId)
	}
