import { useNavigate, Link, useLocation } from 'react-router-dom'
import { TableComposable, Thead, Tbody, Tr, Th, Td, ActionsColumn } from '@patternfly/react-table'
import { Dictionary } from '@reduxjs/toolkit'
// ---
import { useAppDispatch, useAppSelector } from '../../../store/store'
import {
	ProxyConfigEntity,
	ProxyConfigMappingEntity,
} from '../../../store/proxy'
import ArrowIcon from '@patternfly/react-icons/dist/esm/icons/arrow-icon'
import PlusIcon from '@patternfly/react-icons/dist/esm/icons/plus-icon'
import PenIcon from '@patternfly/react-icons/dist/esm/icons/pen-icon'
import CloseIcon from '@patternfly/react-icons/dist/esm/icons/close-icon'
import { Button, ButtonGroup } from 'reactstrap'

type ProxyMappingListItemProps = {
	proxy :ProxyConfigEntity
	mapping :ProxyConfigMappingEntity
	columnNames :Dictionary<string>
	onEdit: (m :ProxyConfigMappingEntity) => void
	onDelete: (m :ProxyConfigMappingEntity) => void
}

export function ProxyMappingListItem(props :ProxyMappingListItemProps)
{
	const {proxy, mapping, columnNames, onEdit, onDelete} = props
	const {navigate, dispatch} = useProxyMappingListItemLocals(mapping)
	
	return (
		<Tr key={mapping.from}>
			<Td>
				<a href={`https://${proxy.domain}${mapping.from}`}
				   target={proxy.domain}>
					<ArrowIcon />
				</a>
			</Td>
			<Td dataLabel={columnNames.from}>
				{mapping.from}
			</Td>
			<Td dataLabel={columnNames.to}>{mapping.to}</Td>
			<Td dataLabel={columnNames.controls} modifier="fitContent">
				<ButtonGroup>
					<button
						className="btn btn-sm btn-outline-secondary"
						onClick={() => onEdit(mapping)}
					>
						<PenIcon />
					</button>
					<button
						className="btn btn-sm btn-outline-danger"
						onClick={() => onDelete(mapping)}
					>
						<CloseIcon />
					</button>
				</ButtonGroup>
			</Td>
		</Tr>
	)
}

/*
<ul className="list-group mb-2">
			<li className="list-group-item">
				<Row>
					<Col sm={6} className="d-flex flex-row">
						<CardTitle tag="h5" className="d-flex justify-content-left align-baseline">
							<Link className="text-dark text-decoration-none" to={`/proxy/${proxy.domain}`}>
								<i className="bi bi-pencil-square" />
								&nbsp;&nbsp;{proxy.name}
							</Link>
						</CardTitle>
						
					</Col>
					<Col sm={6} className="d-flex flex-row-reverse">
						<code>
							{mappings.length} endpoint{mappings.length>1&&'s'}
						</code>
					</Col>
				</Row>
			</li>
			<li className="list-group-item">
				<a href={`https://${proxy.domain}`} target="new" className="text-medium-dark">https://{proxy.domain}</a>
			</li>
		</ul>
		
 */

function useProxyMappingListItemLocals(mapping :ProxyConfigMappingEntity)
{
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	
	
	return {
		navigate,
		dispatch
	}
}
