
export const invariant = function (condition :boolean, format? :string, ...args :Array<any>)
{
	if(process.env.NODE_ENV !== 'production')
	{
		if(format === undefined)
			throw new Error('invariant requires an error message argument')
	}
	
	if(!condition)
	{
		var error
		
		if(format === undefined)
		{
			error = new Error(
				'Minified exception occurred; use the non-minified dev environment ' +
				'for the full error message and additional helpful warnings.'
			)
		}
		else
		{
			var argIndex = 0
			error = new Error(
				format.replace(/%s/g, function () { return args[argIndex++] })
			)
			error.name = 'Invariant Violation'
		}
		
		//@ts-ignore
		error.framesToPop = 1 // we don't care about invariant's own frame
  
		throw error
	}
}
