import _isString from 'lodash/isString'
import { FormFieldState } from '../State/Element'
import { FieldValidationState } from '../ValidationContext'

export async function required(el :FormFieldState, state :FieldValidationState) :Promise<FieldValidationState>
{
	 //console.info('validating required', el.name, el.value, state);
	// console.info('required?', el.schema.required);
	// console.info('visited?', el.visited);

	const schema = el.schema
	
	if(!schema || !el.visited)
		return state

	if(schema.required && (el.value === '' || el.value === null || el.value === undefined || /^\s*$/.test(el.value)))
	{
		console.warn('required test failed')

		state.valid = false
		state.message = _isString(schema.required)
			? schema.required
			: schema.placeholder
				? schema.placeholder + ' is required'
				: schema.label
					? schema.label + ' is required'
					: schema.name + ' is required'

		state.errors.push({message: 'This field is required'})
	}
	
	// Should not be needed?
	//else if (!schema.required && (!el.value || /^\s*$/.test(el.value)))
	//{
	//	//console.warn('field is empty and not required');
	//	reject(state)
	//}
	
	return state
}
