import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
//@ts-ignore
import logger from 'redux-logger'
// ---
import { userReducer } from './user'
import { viewReducer } from './view'
import { proxyConfigReducer } from './proxy'
import { remoteReducer } from './remote'
import { adminReducer } from './admin'
// ---
import { orchestratorMiddleware } from './orchestration'


//const timeoutScheduler = (store: Store) => (next: Dispatch<AnyAction>) => (action: any) =>
//{
//	if(!action.meta || !action.meta.delay)
//	{
//		return next(action)
//	}
//
//	const timeoutId = setTimeout(() => next(action), action.meta.delay)
//
//	return function cancel()
//	{
//		clearTimeout(timeoutId)
//	}
//}

export const store = configureStore({
		reducer: {
			user: userReducer,
			view: viewReducer,
			proxy: proxyConfigReducer,
			remote: remoteReducer,
			admin: adminReducer
		},
		middleware: (getDefaultMiddleware) =>
			//@ts-ignore
			getDefaultMiddleware().concat(orchestratorMiddleware)
	})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
