import { useEffect, useState } from 'react'

type StateSetter<StateType> = (state :StateType) => void

export function useSyncronizedState<StateType>(initialOrExternalState :StateType, externalStateSetter? :StateSetter<StateType>)
	:[StateType, StateSetter<StateType>]
{
	const [finalState, setInternalState] = useState<StateType>(initialOrExternalState)
	
	useEffect(() =>
	{
		if(externalStateSetter !== undefined && initialOrExternalState !== finalState)
			externalStateSetter(finalState)
	}, [finalState])
	
	useEffect(() =>
	{
		if(finalState !== initialOrExternalState)
			setInternalState(initialOrExternalState)
	}, [initialOrExternalState])
	
	const setFinalState :StateSetter<StateType> = externalStateSetter || setInternalState
	
	return [finalState, setFinalState]
}
