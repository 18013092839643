import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../store'
import { apiRequestCreateProxy, apiRequestProxies } from '../../api/actions/proxy'
import { ProxyConfig, ProxyConfigList } from './types'
import { UserStateType } from '../user'
import { endLoading, startLoading } from '../view'
import { updateSingleProxy } from '../proxy'
import { apiRequestCommand } from '../../api/actions/proxy/command'

export async function commandValidate(dispatch :AppDispatch, user :UserStateType) :Promise<any>
{
	dispatch({type: 'proxy/command/validate'})
	
	return apiRequestCommand(user, '')
		.then(response =>
			dispatch({type:'proxy/command/initiated', payload: response}))
		.catch(e =>
			dispatch({type:'proxy/command/failed', payload: e}))
}

export async function commandProcessAll(dispatch :AppDispatch, user :UserStateType) :Promise<any>
{
	dispatch({type: 'proxy/command/process/all'})
	
	return apiRequestCommand(user, 'process/all')
		.then(response =>
			dispatch({type:'proxy/command/initiated', payload: response}))
		.catch(e =>
			dispatch({type:'proxy/command/failed', payload: e.message}))
}

export async function commandProcessSingle(dispatch :AppDispatch, user :UserStateType, proxyId :string) :Promise<any>
{
	dispatch({type: 'proxy/command/process/single', payload: proxyId})
	
	return apiRequestCommand(user, `process/${proxyId}`)
		.then(response =>
			dispatch({type:'proxy/command/initiated', payload: response}))
		.catch(e =>
			dispatch({type:'proxy/command/failed', payload: e.message}))
}
