import io from 'socket.io-client'
import { apiDomain } from '../../config'
import { Store } from 'redux'
import { initProxies, ProxyConfigList, resetProxyConfigs } from '../../store/proxy'
import { eventBegin, eventEnd, eventErrLog, eventLog, statusChanged } from '../../store/remote'
import { orchestrateOn } from '../../store/orchestration'
import { appVisibilityChange } from '../../store/view'
import { setLoggedOut, setSessionState, UserStateType } from '../../store/user'
import { RootState } from '../../store/store'
import { fetchProxyConfigs } from '../../store/proxy/crud'
import { Socket } from 'socket.io-client/build/esm/socket'

export async function initSocketIo(store :Store)
{
	const dispatch = store.dispatch
	let connected = false
	let socket :Socket
	
	const initSocketIo = (user :UserStateType) =>
	{
		//console.warn('initSocketIo')
		
		if(socket)
			socket.close()
		
		connected = false
		
		socket = io(`wss://${apiDomain}`,
		{
			transports: ['websocket'],
			//autoConnect: false,
			//reconnection: false,
			auth: {
				token: user.token
			}
		})
		
		socket.io.on("close", () =>
			connected = false)
	
		socket.on("connect_error", (err) =>
		{
			console.log(`socketio connect_error : ${err.message}`)
		})
		
		socket.on('connected', (data :{status: string, proxies: ProxyConfigList}) =>
		{
			console.warn('Connected.', data)
			
			connected = true
			
			try
			{
				dispatch(initProxies(data.proxies))
				dispatch(statusChanged(data.status))
			}
			catch(e)
			{
				console.error(e)
			}
		})
		
		socket.on('process-begin',
			({id, command, time, status}
					:{id :string, command: string, time :number, status :string}) =>
		{
			console.warn(`Process ${id} begin ${time}`)
			
			dispatch(statusChanged(status))
			dispatch(eventBegin({id, started: time, command}))
		})
		
		socket.on('process-log',
			({id, text}
					:{id :string, text :string}) =>
		{
			console.warn('-->', text)
			dispatch(eventLog({id, text}))
		})
		
		socket.on('process-err', ({id, text}) =>
		{
			console.error('--!', text)
			dispatch(eventErrLog({id, text}))
		})
		
		socket.on('process-end', ({id, ended, code, text, status}) =>
		{
			console.warn(`Process ${id} end ${ended} with code ${code}`)
			dispatch(eventEnd({id, ended, code, text}))
			dispatch(statusChanged(status))
		})
		
		//console.info('SocketIo initialized')
	}
	
	// ---
	
	// Listen for app init, visibility change event and session state change. If view is visible,
	// user is logged in and state is not yet initialized, then we need to fetch and initialize proxies
	orchestrateOn(['initialize', appVisibilityChange.type, setSessionState.type],
		(store, action, oldState: RootState) =>
	{
		const state = store.getState() as RootState
		
		//console.warn('socketio orchestration event', state.view.visible, state.user.loggedIn, state.proxy.initialized)
		
		if(state.view.visible && state.user.loggedIn)
		{
			if(!socket || !connected)
				initSocketIo(state.user)
		}
	})
	
	// When user logs out, clear any loaded data
	orchestrateOn(setLoggedOut.type, (store) =>
	{
		socket.disconnect()
	})
}
