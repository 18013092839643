import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _isString from 'lodash/isString'
import {
	ProxyConfig,
	ProxyConfigEntity,
	ProxyConfigEntityList,
	ProxyConfigList, ProxyConfigMapping,
	ProxyConfigMappingEntity,
	ProxyEntityIndexes,
} from './proxy/types'
import { RootState } from './store'
import {
	allEntries,
	entriesMatcing,
	firstEntry,
	proxyConfigMappingToEntity,
	proxyConfigToEntity,
	proxyEntityIndexer,
	removeProxyEntries,
	removeProxyMappingEntries,
	updateProxyEntries,
	updateProxyMappingEntries
} from './proxy/adapter'

export * from './proxy/types'

interface ProxyConfigState
{
	initialized :boolean,
	proxies :ProxyEntityIndexes
}

const initialState :ProxyConfigState = {
	initialized: false,
	proxies: proxyEntityIndexer.empty()
}

const proxyConfigSlice = createSlice(
	{
		name: 'proxy',
		initialState,
		reducers: {
			initProxies: (state, action :PayloadAction<ProxyConfigList>) =>
            {
				const proxies = action.payload
				
				state.proxies = updateProxyEntries(state.proxies, proxies.map(proxyConfigToEntity))
				state.initialized = true
			},
			setNewProxy: (state, action :PayloadAction<ProxyConfig>) =>
            {
				const proxy = proxyConfigToEntity(action.payload)
				proxy.modified = false
				state.proxies = updateProxyEntries(state.proxies, [proxy])
			},
			updateSingleProxy: (state, action :PayloadAction<ProxyConfigEntity>) =>
            {
				const proxy = action.payload
				proxy.modified = true
				state.proxies = updateProxyEntries(state.proxies, [proxy])
			},
			// Accepts either a domain or an object from which the ID can be derived by the adapter
			delSingleProxy(state, action)
			{
				if(_isString(action.payload))
					state.proxies = removeProxyEntries(state.proxies, [action.payload])
				else
					state.proxies = removeProxyEntries(state.proxies, [action.payload.id])
			},
			resetProxyConfigs: (state) =>
            {
				state.initialized = false
				state.proxies = proxyEntityIndexer.empty()
			},
			
			newProxyMapping: (state, action :PayloadAction<{ proxyId :string, entity: ProxyConfigMapping }>) =>
            {
				const proxyId = action.payload.proxyId
				const entity = proxyConfigMappingToEntity(action.payload.entity)
				
				const proxyEntity = firstEntry(state.proxies.byId, [proxyId])
				
				if(proxyEntity)
				{
					proxyEntity.mappings = updateProxyMappingEntries(proxyEntity.mappings, [entity])
					state.proxies = updateProxyEntries(state.proxies, [proxyEntity])
				}
				else
					console.error(`Can't find a proxy by id ${proxyId}`)
			},
			updateProxyMapping: (state, action :PayloadAction<{ proxyId :string, entity: ProxyConfigMappingEntity }>) =>
            {
				const proxyId = action.payload.proxyId
				const entity = action.payload.entity
				
				const proxyEntity = firstEntry(state.proxies.byId, [proxyId])
				
				if(proxyEntity)
				{
					proxyEntity.modified = true
					proxyEntity.mappings = updateProxyMappingEntries(proxyEntity.mappings, [entity])
					state.proxies = updateProxyEntries(state.proxies, [proxyEntity])
				}
				else
					console.error(`Can't find a proxy by id ${proxyId}`)
			},
			removeProxyMapping: (state, action :PayloadAction<{ proxyId :string, entity :ProxyConfigMappingEntity }>) =>
            {
				const proxyId = action.payload.proxyId
				const mappingEntity = action.payload.entity
				const id = mappingEntity.id
				
				const proxyEntity = firstEntry(state.proxies.byId, [proxyId])
				
				if(proxyEntity)
				{
					proxyEntity.modified = true
					proxyEntity.mappings = removeProxyMappingEntries(proxyEntity.mappings, [id])
					state.proxies = updateProxyEntries(state.proxies, [proxyEntity])
				}
				else
					console.error(`Can't find a proxy by id ${proxyId}`)
			},
		}
	})

// Action creators are generated for each case reducer function
export const { initProxies, updateSingleProxy, setNewProxy, delSingleProxy, resetProxyConfigs, updateProxyMapping, removeProxyMapping } = proxyConfigSlice.actions
export const proxyConfigReducer = proxyConfigSlice.reducer
export const selectProxyState = (state: RootState) => state.proxy

// ---

export const selectAllProxies = (state :RootState) :ProxyConfigEntityList =>
	allEntries(state.proxy.proxies.byDomain)

export const selectAllUnarchivedProxies = (state :RootState) =>
		entriesMatcing(state.proxy.proxies.byArchived, [false])

export const selectByDomain = (domain :string) =>
	(state :RootState) : ProxyConfigEntity|undefined|void =>
		firstEntry(state.proxy.proxies.byDomain, [domain])


// ---

export const selectProxyMappingsByDomain = (proxyDomain :string) =>
	(state :RootState) =>
		allEntries(selectByDomain(proxyDomain)(state)?.mappings.byFrom)

export const selectProxyMappingByFrom = (proxyDomain :string, mappingFrom :string) =>
	(state :RootState) :ProxyConfigMappingEntity|undefined|void =>
		firstEntry(selectByDomain(proxyDomain)(state)?.mappings.byFrom, [mappingFrom])
