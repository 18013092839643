import { useNavigate, Link, useLocation } from 'react-router-dom'
import { Card, CardTitle, CardBody, CardFooter, CardHeader } from '@patternfly/react-core'
// ---
import { useAppDispatch, useAppSelector } from '../../store/store'
import { requestLogin, selectUserState } from '../../store/user'
import { Fragment, useEffect, useState } from 'react'
import { selectAllUnarchivedProxies } from '../../store/proxy'
import { ProxyListItem } from './ProxyList/ProxyListItem'
import { NewProxy } from './NewProxy'
import { fetchProxyConfigs } from '../../store/proxy/crud'
import { commandProcessAll, commandValidate } from '../../store/proxy/command'
import { selectRemoteState } from '../../store/remote'
import { DataList, Split, SplitItem } from '@patternfly/react-core'
import { ButtonGroup } from 'reactstrap'
import { ConfirmButton } from './ConfirmButton'
import ArrowIcon from '@patternfly/react-icons/dist/esm/icons/arrow-icon'
import SyncIcon from '@patternfly/react-icons/dist/esm/icons/sync-icon'

export function ProxyListComponent()
{
	const {
		user,
		remote,
		proxies,
		disabled,
		
		onNewProxy,
		newIsOpen,
		setNewIsOpen,
		
		onReload,
		onProcess
	} = useProxyListLocals()
	
	return (
		<Fragment>
			<Card isFullHeight>
				<CardHeader>
					<Split hasGutter className="full-width">
						<SplitItem>
							<CardTitle>
								<h4>
									{proxies.length} Proxy Configuration{proxies.length>1&&'s'}
								</h4>
							</CardTitle>
						</SplitItem>
						<SplitItem isFilled></SplitItem>
						<SplitItem>
							<ButtonGroup>
								<button {...{disabled, onClick:onNewProxy}}
										className="btn btn-sm btn-outline-primary">
									<i className="bi bi-pencil-square" /> New Proxy
								</button>
								<button {...{disabled, onClick:onReload}}
										className="btn btn-sm btn-outline-secondary">
									<i className="bi bi-arrow-clockwise" /> Reload
								</button>
								{!remote.active &&
									<ConfirmButton {...{disabled, onConfirm: onProcess, icon: <SyncIcon />}}
											className="btn btn-sm btn-outline-danger">
										Apply All
									</ConfirmButton>
								}
							</ButtonGroup>
						</SplitItem>
					</Split>
				</CardHeader>
				
				<CardBody>
					{proxies.length === 0
						?
						<div>Nothing here</div>
						:
						<DataList aria-label="List of proxies">
							{proxies.map(proxy =>
								<ProxyListItem {...{proxy, key:proxy.domain}} />)}
						</DataList>
					}
				</CardBody>
			</Card>
			
			<NewProxy isOpen={newIsOpen} setIsOpen={setNewIsOpen} />
		</Fragment>
	)
}

function useProxyListLocals()
{
	const user = useAppSelector(selectUserState)
	const remote = useAppSelector(selectRemoteState)
	const proxies = useAppSelector(selectAllUnarchivedProxies)
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const [disabled, setDisabled] = useState(false)
	const [newIsOpen, setNewIsOpen] = useState(false)
	
	console.log('proxies list should all be unarchived', proxies)
	
	const onNewProxy = (e : any) =>
	{
		e.preventDefault()
		
		if(disabled)
			return
		
		//navigate('/new')
		setNewIsOpen(true)
	}
	
	const onReload = async (e : any) =>
	{
		e.preventDefault()
		
		if(disabled)
			return
		
		setDisabled(true)
		
		await fetchProxyConfigs(dispatch, user)
		
		setDisabled(false)
	}
	
	const onProcess = async (e : any) =>
	{
		e.preventDefault()
		
		if(disabled)
			return
		
		setDisabled(true)
		
		//await commandValidate(dispatch, user)
		await commandProcessAll(dispatch, user)
		navigate('/remote')
		
		setDisabled(false)
	}
	
	return {
		user,
		remote,
		proxies,
		disabled, setDisabled,
		onNewProxy,
		newIsOpen,
		setNewIsOpen,
		onReload,
		onProcess,
		dispatch
	}
}
