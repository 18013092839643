// ----------------------------------

export const DATA_SCHEMA_STRUCTURE = Symbol('DATA_SCHEMA_STRUCTURE')
export const DATA_SCHEMA_SEQUENCE = Symbol('DATA_SCHEMA_SEQUENCE')
export const DATA_SCHEMA_ELEMENT = Symbol('DATA_SCHEMA_ELEMENT')

export const DATA_FORM_STATE = Symbol('DATA_FORM_STATE')
export const DATA_FORM_STATE_SEQUENCE = Symbol('DATA_FORM_STATE_SEQUENCE')
export const DATA_FORM_STATE_ELEMENT = Symbol('DATA_FORM_STATE_ELEMENT')

// ---

export function isPlaceholder(entity :any)
{
	/** @see createPlaceholder */
	// I'm having trouble retaining a symbol set on an object,
	// even when set on the prototype and the prototype is intact. So confused.
	return entity === undefined || (!!entity && entity.isPlaceholder)
}

export function isStructure(entity :any)
{
	return !!entity && entity[DATA_SCHEMA_STRUCTURE]
}

export function isSequence(entity :any)
{
	return !!entity && entity[DATA_SCHEMA_SEQUENCE]
}

export function isElement(entity :any)
{
	return !!entity && entity[DATA_SCHEMA_ELEMENT]
}

export function isSchemaLike(entity :any)
{
	return !!entity && (entity[DATA_SCHEMA_STRUCTURE] || entity[DATA_SCHEMA_SEQUENCE] || entity[DATA_SCHEMA_ELEMENT])
}

// ---

export function isFormStateLike(entity :any)
{
	return entity && (entity[DATA_FORM_STATE] || entity[DATA_FORM_STATE_SEQUENCE] || entity[DATA_FORM_STATE_ELEMENT])
}

export function isFormState(entity :any)
{
	return entity && entity[DATA_FORM_STATE]
}

export function isFormStateSequence(entity :any)
{
	return entity && entity[DATA_FORM_STATE_SEQUENCE]
}

export function isFormStateElement(entity :any)
{
	return entity && entity[DATA_FORM_STATE_ELEMENT]
}

// ---

export function varType(thing :any)
{
	return thing && thing.constructor ? thing.constructor.name : typeof thing
}
