import { FormFieldState } from '../../library/rathe-strongly-abstract/Form/State/Element'
import { FieldValidationState } from '../../library/rathe-strongly-abstract/Form/ValidationContext'
import { required } from '../../library/rathe-strongly-abstract/Form/validators'

export async function validateFromMapping(el :FormFieldState, state :FieldValidationState)
{
	const schema = el.schema

	if(!schema || !el.visited)
		return state
	
	state = await required(el, state)
	
	if(state.valid === true && (el.value[0] !== '/' || el.value[el.value.length-1] !== '/'))
	{
		state.valid = false
		state.message = `'${schema.label || schema.name || schema.placeholder}' should begin and end with a /`
	}
	
	return state
}
