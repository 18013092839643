import { useNavigate, Link, useLocation } from 'react-router-dom'
import { TableComposable, Thead, Tbody, Tr, Th, Td } from '@patternfly/react-table'
import { Button, ButtonGroup } from 'reactstrap'
import PlusIcon from '@patternfly/react-icons/dist/esm/icons/plus-icon'
// ---
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { Fragment, useState } from 'react'
import {
	ProxyConfigEntity,
	selectProxyMappingsByDomain,
} from '../../../store/proxy'
import ArrowIcon from '@patternfly/react-icons/dist/esm/icons/arrow-icon'
import PenIcon from '@patternfly/react-icons/dist/esm/icons/pen-icon'
import CloseIcon from '@patternfly/react-icons/dist/esm/icons/close-icon'

const columnNames = {
	controls: '',
	from: 'From',
	to: 'To',
}

export function ProxyListItemDetails({proxy} :{proxy :ProxyConfigEntity})
{
	const {
		mappings,
	} = useProxyMappingListLocals(proxy)
	
	return (
		<TableComposable aria-label="Compound expandable table">
			<Thead>
				<Tr>
					<Th></Th>
					<Th>{columnNames.from}</Th>
					<Th>{columnNames.to}</Th>
				</Tr>
			</Thead>
			<Tbody>
				{mappings.map(mapping =>
					<Tr key={mapping.from}>
						<Td dataLabel={columnNames.controls} modifier="fitContent">
							<a href={`https://${proxy.domain}${mapping.from}`}
							   target={proxy.domain}>
								<ArrowIcon />
							</a>
						</Td>
						<Td dataLabel={columnNames.from}>
							{mapping.from}
						</Td>
						<Td dataLabel={columnNames.to}>{mapping.to}</Td>
					</Tr>
				)}
			</Tbody>
		</TableComposable>
	)
}

function useProxyMappingListLocals(proxy :ProxyConfigEntity)
{
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	
	let requestedDomain = proxy.domain
	let mappings = useAppSelector(selectProxyMappingsByDomain(requestedDomain))
	
	return {
		mappings,
		navigate,
		dispatch,
	}
}
