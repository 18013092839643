import { AnyAction, Dispatch, Store } from 'redux'
import _isArray from 'lodash/isArray'
import _isFunction from 'lodash/isFunction'

export type OrchestrationEventSource = string|Array<string>
export type OrchestrationHandler = { (store : Store, action :AnyAction, oldState :any) : void }
export type OrchestrationHandlerList = Array<OrchestrationHandler>

const handlerRepo = new Map<string, OrchestrationHandlerList>()

const addHandler = (singleEvent :string, method :OrchestrationHandler) =>
{
	let set :OrchestrationHandlerList
	
	if(handlerRepo.has(singleEvent))
		handlerRepo.set(singleEvent, [...handlerRepo.get(singleEvent)!, method])
	else
		handlerRepo.set(singleEvent, [method])
}

export function orchestrateOn(event :OrchestrationEventSource, method :OrchestrationHandler)
{
	if(_isArray(event))
		event.map(e =>
			addHandler(e, method))
	else
		addHandler(event, method)
}

export const orchestratorMiddleware = (store : Store) =>
{
	//console.warn('initialize orchestrator')
	
	return (next : Dispatch<AnyAction>) => (action : any) =>
	{
		if(handlerRepo.has(action.type))
		{
			//console.warn('orchestrator has', action.type)
			
			let handlers = handlerRepo.get(action.type)
			
			if(handlers)
			{
				const oldState = store.getState()
				let r = next(action)
				
				for(let h of handlers)
					h(store, action, oldState)
				
				return r
			}
		}
		//else
		//	console.warn('orchestrator does NOT have', action.type)
		
		return next(action)
	}
}
