import { action, autorun, computed, makeObservable, observable, runInAction } from 'mobx'
import { gMatchNameInArray as stringInArrayMatcher } from '../utils'
import { FormFieldState } from './State/Element'
import _isFunction from 'lodash/isFunction'

export type ValidatorType = (force? :boolean) => Promise<any>
export type ValidatorProviderType = {validate: (force? :boolean) => Promise<any>}

export type FieldValidationState = {
	el :FormFieldState,
	name :string
	valid :boolean
	message :string
	errors :Array<{message :string}>
}

export type ValidationContextState = {
	validated :boolean
	valid :boolean
	modified :boolean
}

export const cleanState :ValidationContextState = {
	validated: false,
	valid: false,
	modified: false
}

export type ValidationContextStateHandler =
	(state: ValidationContextState) => void

export class ValidationContext
{
	fields :Map<string, FormFieldState> = new Map()
	validators :Map<string, ValidatorType> = new Map()
	state = Object.assign({}, cleanState)
	
	private onStateChangedHandler? :ValidationContextStateHandler
	
	constructor(fields? :Array<FormFieldState>)
	{
		if(fields)
			this.addFields(fields)
		
		makeObservable(this, {
			fields: observable,
			state: observable,
		})
		
		this.listen()
	}
	
	private _listener :Function|undefined
	
	listen()
	{
		if(this._listener)
			this._listener()
		
		this._listener = autorun(() =>
		{
			//console.warn('fields changed')
			
			let validated = true
			let valid = true
			let modified = false
			
			for(let f of this.fields.values())
			{
				if(!f.validated)
				{
					//console.info(f.name, 'is validated')
					validated = false
				}
				if(!f.valid)
				{
					//console.info(f.name, 'is valid')
					valid = false
				}
				if(f.modified)
				{
					//console.info(f.name, 'is modified', f.value, f.initial)
					modified = true
				}
			}
			
			runInAction(() =>
			{
				this.state = {validated, valid, modified}
				
				if(this.onStateChangedHandler)
					this.onStateChangedHandler(this.state)
			})
		})
	}
	
	onStateChanged(onStateChangedHandler :ValidationContextStateHandler)
	{
		this.onStateChangedHandler = onStateChangedHandler
	}
	
	addValidator(name :string, validator :ValidatorType|ValidatorProviderType)
	{
		if(_isFunction(validator))
			this.validators.set(name, validator)
		else
			this.validators.set(name, (force) =>
				validator.validate(force))
	}
	
	remove(name :string)
	{
		this.fields.delete(name)
		this.validators.delete(name)
		
		this.listen()
	}
	
	addFields(fields :Array<FormFieldState>)
	{
		const destructors :Array<(f :any) => any> = []
		
		for(let field of fields)
			destructors.push(this.addField(field))
		
		return destructors
	}
	
	addField(state :FormFieldState) :(f :any) => any
	{
		runInAction(() =>
			this.fields.set(state.key, state))
		
		this.listen()
		
		this.addValidator(state.key, (force? :boolean) =>
		{
			return state.validate(force)
				.then(() => state.valid)
				.catch(() => false)
		})
		
		return () =>
		{
			this.remove(state.key)
		}
	}
	
	filterFields(callback :(name :string) => boolean) :Array<FormFieldState>
	{
		let elem :FormFieldState,
			filtered :Array<FormFieldState> = []
		
		for(elem of this.fields.values())
		{
			if(callback(elem.key))
				filtered.push(elem)
		}
		
		return filtered
	}
	
	filterFieldsByName(field_names :Array<string>) :Array<FormFieldState>
	{
		return this.filterFields(stringInArrayMatcher(field_names, (i :any) => i.key))
	}
	
	// ---
	
	subset(field_names :Array<string>) :ValidationContext
	{
		const filtered = this.filterFieldsByName(field_names)
		const surrogate = new ValidationContext()
		surrogate.addFields(filtered)
		
		return surrogate
	}
	
	getFieldValues(only_valid = false)
	{
		const output :any = {}
		
		var elem :FormFieldState
		
		for(elem of this.fields.values())
		{
			if(only_valid && !elem.valid)
				continue
			
			if(elem.key)
				output[elem.key] = String(elem.value || '')
		}
		
		return output
	}
	
	setFieldValues(source = {} as any)
	{
		for(var elem of this.fields.values())
		{
			const name = elem.schema?.name
			
			if(name && name in source)
			{
				// console.warn('setFieldStateValues', name, source[name])
				elem.set(source[name] || '')
			}
		}
	}
	
	validate(force = true)
	{
		const validators = [...this.validators.values()]
			.map(v => v(force))
		
		// console.warn('validators at validate', validators)
		
		return Promise.allSettled(validators)
			.then(action('allValidated',
				results =>
				{
					return results.every(p =>
							p.status === "fulfilled"
								? p.value
								: false)
				}))
	}
}

// Made this for useSyncExternalStore, but it's not working as I expected
export const createValidationContextStore = (fields? :Array<FormFieldState>) =>
{
	console.warn('+new validation context')
	
	let context = new ValidationContext()
	
	context.onStateChanged(() =>
	{
		console.info('+caling listeners')
		listeners.forEach((l) => l())
	})
	
	const getState = () =>
		context.state
	
	const listeners = new Set<Function>()
	
	const setState = (fn :Function) =>
	{
		console.info('+setState')

		context.state = fn(context.state)
		listeners.forEach((l) => l())
	}
	
	const subscribe = (listener :any) =>
	{
		console.info('+Subscribe listener')
		
		listeners.add(listener)
		return () => listeners.delete(listener)
	}
	
	return {getState, setState, subscribe, context}
}
