import { Fragment, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../store/store'
import { selectRemoteState } from '../../../store/remote'
import { capitalize } from 'lodash'

type StatusButtonProps =
	{
	}

export function StatusButton(props : StatusButtonProps)
{
	const remote = useAppSelector(selectRemoteState)
	
	let classNames = "bg-medium btn nav-link status px-3"
	
	if(remote.status !== 'idle')
		classNames = `${classNames} btn-warning`
	else
		classNames = `${classNames} btn-secondary`
	
	return (
		<Link className={classNames} to="/remote">
			{remote.status !== 'idle'
				?
				<>
					<div className="spinner-border spinner-small" role="status">
						<span className="visually-hidden">{remote.status}...</span>
					</div>&nbsp;&nbsp;
					{capitalize(remote.status)}
				</>
				:
				<>Status: {capitalize(remote.status)}</>
			}
		</Link>
	)
}
