import { hash } from '../../utils'

export interface Notification
{
	id? :number|string
	time? :number
	type? :string
	title? :string
	message :string
	meta? :any
}

export interface NotificationEntity extends Notification
{
	id :number|string
	time :number
	type :string
	meta :any
}

export type NotificationEntityList = Array<Notification>

// ---

export const normalizeNotification = (data :Notification) : NotificationEntity => {
	
	let meta = data.meta || {}
	
	return {
		id: data.id || 1,
		time: data.time || Date.now(),
		type: data.type || 'error',
		title: data.title,
		message: data.message,
		meta
	}
}
