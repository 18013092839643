import { ReactNode, useState } from 'react'
import { Toast, ToastBody, ToastHeader } from 'reactstrap'
import { useAppSelector } from '../../store/store'
import { selectAllNotifications, selectViewState } from '../../store/view'
import { NotificationEntity } from '../../store/view/types'

export function NotificationList()
{
	const notifications = useAppSelector(selectAllNotifications)
	
	// ms-sm-auto
	return (
		<div className="toast-container position-absolute p-3 top-0 end-0">
			{notifications.map(n =>
				<NotificationCard {...n} />
			)}
		</div>
	)
}

const classNames = (n :NotificationEntity) =>
{
	if(n.type === 'error')
		return 'text-red bg-black'
	return 'text-black bg-white'
}

export function NotificationCard(n :NotificationEntity)
{
	const [show, setShow] = useState(true)
	
	// ms-sm-auto
	return (
		<div className="toast-container position-absolute p-3 top-0 end-0">
			{show &&
				<Toast fade className={classNames(n)}>
					<ToastHeader toggle={() => setShow(!show)}>{n.title}</ToastHeader>
					<ToastBody>{n.message}</ToastBody>
				</Toast>
			}
		</div>
	)
}
