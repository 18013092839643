import { Fragment, ReactNode } from 'react'
import './Loading/Loading.scss'
import { useAppSelector } from '../../store/store'
import { selectViewState } from '../../store/view'

type LoadingPropsType =
	{
		children?: ReactNode
	}

export function Loading(props : LoadingPropsType)
{
	const view = useAppSelector(selectViewState)
	
	const loading = view.loading
	const loading_message = view.loading_message
	const children = props.children
	
	return (
		<Fragment>
			<div id="overlay" style={{display:loading?'':'none'}}>
				<div className="spinner"></div>
				<br/>
				{loading_message || 'Loading, please wait ...'}
			</div>
		</Fragment>
	)
}
