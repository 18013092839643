import { Fragment, ReactNode } from 'react'
import { NotificationList } from './Notification'
import {
	Divider,
	Page,
	PageSection, PageSectionVariants,
	Panel,
	PanelHeader,
	PanelMain,
	PanelMainBody
} from '@patternfly/react-core'

type LayoutPropsType =
	{
		Header?: ReactNode,
		children?: ReactNode
	}

export function Layout(props : LayoutPropsType)
{
	const children = props.children
	const Header = props.Header
	
	// ms-sm-auto
	return (
		<Page header={Header}>
			<PageSection variant={PageSectionVariants.default}>
				{children ? children : null}
			</PageSection>
		</Page>
	)
}
