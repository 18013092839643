import React, { useState } from 'react'
import {
	Button,
	Col,
	Form, FormFeedback,
	FormGroup, InputGroup,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row
} from 'reactstrap'
import { SchemaElementListType, useFormFieldStates, useValidationContext } from '../../../store/schema'
import { SchemaInput, SchemaLabel, SchemaError } from '../forms/SchemaField'
import { requestLogin, selectUserState } from '../../../store/user'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import {
	ProxyConfig,
	ProxyConfigEntity,
	ProxyConfigMapping,
	ProxyConfigMappingEntity,
} from '../../../store/proxy/types'
import { selectProxyState, updateProxyMapping } from '../../../store/proxy'
import { useNavigate } from 'react-router-dom'
import { proxyConfigMappingToEntity } from '../../../store/proxy/adapter'
import { FormFieldState } from '../../../library/rathe-strongly-abstract/Form/State/Element'
import { FieldValidationState } from '../../../library/rathe-strongly-abstract/Form/ValidationContext'
import { required } from '../../../library/rathe-strongly-abstract/Form/validators'
import { validateFromMapping } from '../../../store/proxy/validator'

export interface EditProxyMappingProps
{
	proxy :ProxyConfigEntity
	mapping? :ProxyConfigMappingEntity
	onClose :Function
}

export function EditProxyMapping(props :EditProxyMappingProps)
{
	//console.warn('render EditProxyMapping')
	
	const {
		from, to,
		context, formState,
		onSave,
		onClose
	} = useProxyMappingLocals(props)
	
	const mapping = props.mapping
	
	if(!mapping)
		return null
	
	return (
		<div>
			<Modal isOpen={true} toggle={() => onClose()} size="lg">
				<ModalHeader>{mapping.id ? 'Edit' : 'New'} Path Mapping</ModalHeader>
				<ModalBody>
					<Form>
						<FormGroup row>
							<SchemaLabel state={from} />
							<Col>
								<SchemaInput state={from} />
								<SchemaError state={from} />
							</Col>
						</FormGroup>
						<FormGroup row>
							<SchemaLabel state={to} />
							<Col>
								<SchemaInput state={to} />
								<SchemaError state={to} />
							</Col>
						</FormGroup>
					</Form>
				</ModalBody>
				<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
					<ModalFooter>
						{formState.validated && !formState.valid &&
							<FormFeedback valid={false} style={{display:'block'}}>
								<em>Please fix errors above</em>
							</FormFeedback>
						}
					</ModalFooter>
					<ModalFooter>
						{formState.modified &&
							<Button color="primary" onClick={onSave}>
								Accept
							</Button>
						}
						{' '}
						<Button color="secondary" onClick={() => onClose()}>
							Cancel
						</Button>
					</ModalFooter>
				</div>
			</Modal>
		</div>
	);
}

function useProxyMappingLocals(props :EditProxyMappingProps)
{
	const proxy = props.proxy
	const mapping = props.mapping
	const onClose = props.onClose
	
	const user = useAppSelector(selectUserState)
	
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	
	const [disabled, setDisabled] = useState(false)
	
	const fields = useFormFieldStates(() => [
		{
			path: proxy.id,
			name: 'from',
			label: 'From',
			placeholder: '/path/from/proxy/',
			required: true,
			initial: mapping && mapping.from,
			validator: validateFromMapping
		},
		{
			path: proxy.id,
			name: 'to',
			label: 'To',
			placeholder: 'https://domain.com/path',
			required: true,
			initial: mapping && mapping.to
		}
	] as SchemaElementListType, [mapping])
	
	const {context, state : formState} = useValidationContext(fields)
	
	const [from, to] = fields
	
	const onSave = async (e :any) =>
	{
		e.preventDefault()
		
		if(disabled)
			return
		
		setDisabled(true)
		
		dispatch(updateProxyMapping({
				proxyId: proxy.id,
				entity: mapping && mapping.id
					? {id: mapping.id, from: from.value, to: to.value}
					: proxyConfigMappingToEntity({from: from.value, to: to.value})
			}))
		
		setDisabled(false)
		
		onClose()
	}
	
	return {
		user,
		context, formState,
		from, to,
		disabled,
		setDisabled,
		onSave,
		onClose,
		dispatch
	}
}
