
let hidden : string|undefined
let visibilityChange : string

if (typeof window.document.hidden !== "undefined")
{ // Opera 12.10 and Firefox 18 and later support
  hidden = "hidden"
  visibilityChange = "visibilitychange"
}
else if (typeof window.document.msHidden !== "undefined")
{
  hidden = "msHidden"
  visibilityChange = "msvisibilitychange"
}
else if (typeof window.document.webkitHidden !== "undefined")
{
  hidden = "webkitHidden";
  visibilityChange = "webkitvisibilitychange";
}

export type VisibilityChangeHandlerType = (visible : boolean) => void

export function onVisibilityChange(handler : VisibilityChangeHandlerType)
{
  if(typeof document.addEventListener === "undefined" || hidden === undefined)
    console.log("This app requires a browser, such as Google Chrome or Firefox, which supports the Page Visibility API.")
  else
  {
    // Handle page visibility change
    const closure = () =>
    {
      //@ts-ignore
      handler(!window.document[hidden])
    }
    document.addEventListener(visibilityChange, closure, false)
  }
}
