import { apiUrl } from '../../config'

type ApiAuthLoginPayload = {
    user :{
		id :string
		email :string
        role :string
    }
    token :string
	error? :string
}

export async function apiRequestLogin({ email, password } : { email : string, password : string }) : Promise<ApiAuthLoginPayload>
{
	const result = await fetch(`${apiUrl}/auth/login`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({email, password})
	})
	return await result.json() as ApiAuthLoginPayload
}

export async function apiRequestLogout()
{

}
