import React from 'react'
import {
	Button,
	Col,
	Form, FormFeedback,
	FormGroup, InputGroup,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row
} from 'reactstrap'
import { useSyncronizedState } from '../../library/rathe-strongly-abstract/hooks/state'
import { SchemaElementListType, useFormFieldStates, useValidationContext } from '../../store/schema'
import { domain as domainValidator, url as urlValidator } from '../../library/rathe-strongly-abstract/Form/validators'
import { SchemaInput, SchemaLabel, SchemaError } from './forms/SchemaField'
import { requestLogin, selectUserState } from '../../store/user'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { createNewProxyConfig } from '../../store/proxy/crud'
import { ProxyConfig } from '../../store/proxy/types'

export interface NewProxyProps
{
	isOpen?: boolean
	setIsOpen? :(open :boolean) => void
}

export function NewProxy(props :NewProxyProps)
{
	const {
		open, setOpen, close,
		name, domain, endpoint,
		context, state,
		onSubmit
	} = useSetupNewProxy(props)
	
	return (
		<div>
			<Modal isOpen={open} toggle={close} size="lg">
				<ModalHeader>New Proxy</ModalHeader>
				<ModalBody>
					<Form>
						<Row>
							<Col md={6}>
								<FormGroup>
									<SchemaLabel state={name} />
									<SchemaInput state={name} />
									<SchemaError state={name} />
								</FormGroup>
							</Col>
							<Col md={6}>
								<FormGroup>
									<SchemaLabel state={domain} />
									<SchemaInput state={domain} />
									<SchemaError state={domain} />
								</FormGroup>
							</Col>
						</Row>
					</Form>
				</ModalBody>
				<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
					<ModalFooter>
						{state.validated && !state.valid &&
							<FormFeedback valid={false} style={{display:'block'}}>
								<em>Please fix errors above</em>
							</FormFeedback>
						}
					</ModalFooter>
					<ModalFooter>
						<Button color="primary" onClick={onSubmit}>
							Create Proxy
						</Button>
						{' '}
						<Button color="secondary" onClick={close}>
							Cancel
						</Button>
					</ModalFooter>
				</div>
			</Modal>
		</div>
	);
}

function useSetupNewProxy(props :NewProxyProps)
{
	const [open, setOpen] = useSyncronizedState<boolean>(!!props.isOpen, props.setIsOpen)
	
	const close = () =>
	{
		// TODO: Implement confirmation without using confirm()
		setOpen(false)
	}
	
	const fields = useFormFieldStates(() => [
		{
			type: 'text',
			name: 'name',
			label: 'Proxy name',
			placeholder: 'Foo Partner',
			required: true,
		},
		{
			type: 'domain',
			name: 'domain',
			label: 'Domain name',
			placeholder: 'example.partner.com',
			required: true,
			validator: domainValidator
		}
	] as SchemaElementListType)
	
	const {context, state} = useValidationContext(fields)
	const [name, domain, endpoint] = fields
	
	const user = useAppSelector(selectUserState)
	const dispatch = useAppDispatch()
	
	const onSubmit = async (e : any) =>
	{
		e.preventDefault()
		
		await context.validate()
		
		let valid = await context.validate()
		
		if(!valid)
			return
		
		await createNewProxyConfig(dispatch, user, {
				name: name.value,
				domain: domain.value,
				status: 'pending',
				mappings: []
			})
		
		setOpen(false);
		fields.forEach(f => f.reset())
	}
	
	return {
		open, setOpen, close,
		name, domain, endpoint,
		context, state,
		onSubmit
	}
}

