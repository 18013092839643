import { apiRequestProxies } from '../actions/proxy'
import { setSessionState, setLoggedOut } from '../../store/user'
import { resetProxyConfigs } from '../../store/proxy'
import { orchestrateOn, OrchestrationEventSource } from '../../store/orchestration'
import { RootState, store } from '../../store/store'
import { appVisibilityChange, endLoading, loadingError, startLoading } from '../../store/view'
import { Store } from 'redux'
import { fetchProxyConfigs } from '../../store/proxy/crud'

export function initProxyOrchestration(store :Store)
{
	// Listen for app init, visibility change event and session state change. If view is visible,
	// user is logged in and state is not yet initialized, then we need to fetch and initialize proxies
	orchestrateOn(['initialize', appVisibilityChange.type, setSessionState.type],
		(store, action, oldState: RootState) =>
	{
		const state = store.getState() as RootState
		
		//console.warn('orchestration event', state.view.visible, state.user.loggedIn, state.proxy.initialized)
		
		if(state.view.visible && state.user.loggedIn && !state.proxy.initialized)
		{
			console.debug('View is visible, user is logged in and state is not yet initialized')
			
			fetchProxyConfigs(store.dispatch, state.user)
		}
	})
	
	// ---
	
	// When user logs out, clear any loaded data
	orchestrateOn(setLoggedOut.type, (store) =>
	{
		store.dispatch(resetProxyConfigs())
	})
}

