import {
	EmptyState,
	EmptyStateVariant
} from '@patternfly/react-core/dist/esm/components/EmptyState/EmptyState'
import { Layout } from './components/Layout'
import { Button, EmptyStateBody, EmptyStateSecondaryActions, Title } from '@patternfly/react-core'
import { Header } from './components/Header'
import { NotificationList } from './components/Notification'
import { ProxyListComponent } from './components/ProxyList'
import { Loading } from './components/Loading'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'

export function NonIdealPage()
{
	return (
		<Fragment>
			<Header />
			<NotificationList />
			<Layout>
				<EmptyState variant={EmptyStateVariant.large}>
					<Title headingLevel="h4" size="md">
						Well, that's not ideal
					</Title>
					<EmptyStateBody>
						<p>
							You have reached a number which has been disconnected or is no longer in service.
						</p>
						<p>
							If you feel you have reached this recording in error, please check the number and try your call again.
						</p>
					</EmptyStateBody>
						<EmptyStateSecondaryActions>
							<Link to="/" className="btn btn-primary">Home</Link>
					</EmptyStateSecondaryActions>
				</EmptyState>
			</Layout>
			<Loading />
		</Fragment>
	)
}
