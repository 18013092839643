import {
	createSearchParams,
	Routes,
	Route,
	useLocation,
	useNavigate,
} from "react-router-dom"
// ---
import { HomePage } from './pages/HomePage'
import { LoginPage } from './pages/LoginPage'
import { EditProxyPage } from './pages/EditProxyPage'
import { RemotePage } from './pages/RemotePage'

import { NonIdealPage } from './pages/NonIdealPage'
import { Fragment, useEffect } from 'react'
import { useAppSelector } from './store/store'
import { selectUserState } from './store/user'

function App()
{
	//console.warn('render App')
	
	useEnsureLoggedIn()
	
	return (
		<Fragment>
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/login" element={<LoginPage />}/>
				<Route path="/proxy/:domain" element={<EditProxyPage />}/>
				<Route path="/remote" element={<RemotePage />}/>
				<Route path="*" element={<NonIdealPage />}/>
			</Routes>
		</Fragment>
	)
}

export default App

function useEnsureLoggedIn()
{
	const location = useLocation()
	const navigate = useNavigate()
	const user = useAppSelector(selectUserState)
	
	useEffect(() =>
	{
		if(!user.loggedIn && location.pathname !== '/login')
		{
			console.warn('navigating to login - redirect pathname is', location.pathname)
			
			navigate({
			  pathname: '/login',
			  search: createSearchParams({l:location.pathname})
			  	.toString()
			})
		}
		
	}, [navigate, location, user])
}
