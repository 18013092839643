import { useNavigate, Link, useLocation } from 'react-router-dom'
import { TableComposable, Thead, Tbody, Tr, Th, Td } from '@patternfly/react-table'
import { Button, ButtonGroup } from 'reactstrap'
import PlusIcon from '@patternfly/react-icons/dist/esm/icons/plus-icon'
// ---
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { Fragment, useState } from 'react'
import {
	ProxyConfigEntity,
	ProxyConfigMappingEntity, removeProxyMapping, selectProxyMappingsByDomain,
} from '../../../store/proxy'
import { ProxyMappingListItem } from './ProxyMappingListItem'
import { EditProxyMapping } from './EditProxyMapping'

const columnNames = {
	from: 'From',
	to: 'To',
	controls: ''
}
export type ProxyMappingListColumnKey = keyof typeof columnNames

export function ProxyMappingList({proxy} :{proxy :ProxyConfigEntity})
{
	const {
		mappings,
		onNewPath,
		onRemovePath,
		editingMappingEntity,
		setEditingMappingEntity
	} = useProxyMappingListLocals(proxy)
	
	return (
		<Fragment>
			<TableComposable aria-label="Compound expandable table">
				<Thead>
					<Tr>
						<Th></Th>
						<Th>{columnNames.from}</Th>
						<Th>{columnNames.to}</Th>
						<Th modifier="fitContent">
							<button className="btn btn-sm btn-outline-secondary" onClick={onNewPath}>
								<PlusIcon />
							</button>
						</Th>
					</Tr>
				</Thead>
				<Tbody>
					{mappings.map(mapping =>
						<ProxyMappingListItem
							{...{
								key: mapping.from,
								proxy,
								mapping,
								columnNames,
								onEdit: m => setEditingMappingEntity(m),
								onDelete: m => onRemovePath(m)
							}}
						/>)
					}
				</Tbody>
			</TableComposable>
			<EditProxyMapping
				proxy={proxy}
				mapping={editingMappingEntity}
				onClose={() => setEditingMappingEntity(undefined)}
			/>
		</Fragment>
	)
}

function useProxyMappingListLocals(proxy :ProxyConfigEntity)
{
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	
	let requestedDomain = proxy.domain
	let mappings = useAppSelector(selectProxyMappingsByDomain(requestedDomain))
	
	const [editingMappingEntity, setEditingMappingEntity] = useState<ProxyConfigMappingEntity>()
	
	const onNewPath = async (e : any) =>
	{
		e.preventDefault()
		
		setEditingMappingEntity({
				id: '',
				from: '',
				to: ''
			})
	}
	
	const onRemovePath = async (mapping: ProxyConfigMappingEntity) =>
	{
		if(!proxy)
			return
		
		dispatch(removeProxyMapping({
				proxyId: proxy.id,
				entity: mapping
			}))
	}
	
	return {
		mappings,
		navigate,
		dispatch,
		onNewPath,
		onRemovePath,
		editingMappingEntity,
		setEditingMappingEntity
	}
}
