// This makes some kind of entity manager
//import { createEntityAdapter } from '@reduxjs/toolkit'
import {
	ProxyConfig,
	ProxyConfigEntity,
	ProxyConfigEntityList,
	ProxyConfigMapping,
	ProxyConfigMappingEntity,
	ProxyConfigMappingEntityList,
	ProxyEntityIndex,
	ProxyEntityIndexes,
	ProxyMappingEntityIndexes
} from './types'
import { Indexer, Index, Keyer, Entry } from 'redux-indexers'
import { hash } from '../../utils'

function attributeKeyer<O>(...k :(keyof O)[]) :Keyer<O>
{
	return function (o :O)
	{
		return k.map(a => o[a])
	}
}

function statusKeyer(...statuses :string[]) :Keyer<ProxyConfigEntity>
{
	return function (o :ProxyConfigEntity)
	{
		return statuses.map(s => o.status === s)
	}
}

export const proxyEntityIndexer = new Indexer<ProxyConfigEntity, ProxyEntityIndexes>("byId")

proxyEntityIndexer.addIndex("byId", attributeKeyer<ProxyConfigEntity>("id"))
proxyEntityIndexer.addIndex("byDomain", attributeKeyer<ProxyConfigEntity>("domain"))
proxyEntityIndexer.addIndex("byDisabled", statusKeyer("disabled"))
proxyEntityIndexer.addIndex("byArchived", statusKeyer("archived"))

export const proxyMappingEntityIndexer = new Indexer<ProxyConfigMappingEntity, ProxyMappingEntityIndexes>("byId")

proxyMappingEntityIndexer.addIndex("byId", attributeKeyer<ProxyConfigMappingEntity>("id"))
proxyMappingEntityIndexer.addIndex("byFrom", attributeKeyer<ProxyConfigMappingEntity>("from"))

// ---

export const proxyConfigToEntity = (data :ProxyConfig) :ProxyConfigEntity =>
{
	const mappings =
		proxyMappingEntityIndexer.update(
			proxyMappingEntityIndexer.empty(),
			data.mappings.map(proxyConfigMappingToEntity))
	
	let proxyEntity = {
		id: data.id!,
		name: data.name,
		domain: data.domain,
		status: data.status,
		timestamp: data.timestamp,
		mappings,
		modified: false
	}
	
	return proxyEntity
}

export const proxyConfigMappingToEntity = (data :ProxyConfigMapping) :ProxyConfigMappingEntity =>
{
	return {
		id: String(hash(data.from, Date.now())),
		from: data.from,
		to: data.to,
	}
}

// ---

export const proxyEntityToConfig = (entity :ProxyConfigEntity) :ProxyConfig =>
{
	const mappings = allEntries<ProxyConfigMappingEntity>(entity.mappings.byFrom)
		.map(proxyMappingEntityToConfig)
	
	return {
		id: entity.id,
		name: entity.name,
		domain: entity.domain,
		status: entity.status,
		timestamp: entity.timestamp,
		mappings
	}
}

export const proxyMappingEntityToConfig = (entity :ProxyConfigMappingEntity) :ProxyConfigMapping =>
{
	return {
		from: entity.from,
		to: entity.to,
	}
}

// ---

export function allEntries<T>(index? :Index<T>) :T[]
{
	let result = [] as T[]
	
	if(index)
	{
		let iterator = Indexer.iterator(index)
		for(let v = iterator(); v; v = iterator())
			result.push(v)
	}
	
	return result
}

export function entriesMatcing<T>(index :Index<T>, key :any[]) :T[]
{
	return Indexer.getAllMatching<T>(index, key)
}

export function firstEntry<T>(index :Index<T> | undefined, key :any[]) :T | undefined | void
{
	if(index)
		return Indexer.getFirstMatching<T>(index, key)
	
	return undefined
}

// ---

export function removeProxyEntries(index :ProxyEntityIndexes, key :any[])
{
	return proxyEntityIndexer.removeByPk(index, key)
}

export function updateProxyEntries(indexes :ProxyEntityIndexes, entries :ProxyConfigEntityList)
{
	for(let e of entries)
		indexes = removeProxyEntries(indexes, [e.id])
	
	indexes = proxyEntityIndexer.update(indexes, entries)
	
	return indexes
}

// ---

export function removeProxyMappingEntries(indexes :ProxyMappingEntityIndexes, key :any[])
{
	return proxyMappingEntityIndexer.removeByPk(indexes, key)
}

export function updateProxyMappingEntries(indexes :ProxyMappingEntityIndexes, entries :ProxyConfigMappingEntityList)
{
	for(let e of entries)
		indexes = removeProxyMappingEntries(indexes, [e.id])
	
	indexes = proxyMappingEntityIndexer.update(indexes, entries)
	
	return indexes
}
