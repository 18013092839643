import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { LogViewer } from '@patternfly/react-log-viewer'
// ---
import { Header } from './components/Header'
import { Layout } from './components/Layout'
import { useAppDispatch, useAppSelector } from '../store/store'
import { selectUserState } from '../store/user'
import { Loading } from './components/Loading'
import { selectLatestEvent, selectRemoteState } from '../store/remote'
import { Card, CardBody, CardFooter, CardHeader, CardText } from 'reactstrap'
import { capitalize } from 'lodash'
import { ProcessEventEntity } from '../store/remote/types'
import { NotificationList } from './components/Notification'

export function RemotePage()
{
	const {remote, latestEvent} = useInitRemote()
	const status = capitalize(remote.status)
	
	const logViewerRef = useRef()
	useMemo(() =>
	{
		if(logViewerRef.current)
		{ //@ts-ignore
			logViewerRef.current.scrollToBottom()
		}
		
	}, [remote])
	
	return (
		<Fragment>
			<Header />
			<NotificationList />
			<Layout>
				<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
					<h1 className="h4">Server Status : <code>{status}</code></h1>
					<div className="btn-toolbar mb-2 mb-md-0">
						<div className="btn-group me-2">
						
						</div>
					</div>
				</div>
				
				{latestEvent &&
					<div className="mb-3">
						<label className="form-label">Last processing log:</label>
						<LogViewer
							ref={logViewerRef}
							hasLineNumbers={true}
							height={600}
							data={latestEvent.log}
							theme="dark"
						/>
					</div>
				}
				
			</Layout>
			<Loading />
		</Fragment>
	)
}

function useInitRemote()
{
	const user = useAppSelector(selectUserState)
	const remote = useAppSelector(selectRemoteState)
	const latestEvent = useAppSelector(selectLatestEvent()) as ProcessEventEntity
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	
	return {
		user,
		remote,
		latestEvent
	}
}
