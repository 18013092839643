import _isPlainObject from 'lodash/isPlainObject'
import _compact from 'lodash/compact'
//import _defaults from 'lodash/defaults'
import {invariant} from '../../invariant'
// ----------------------------------
import {varType, DATA_SCHEMA_ELEMENT, isStructure, isSequence} from '../types'
import { FieldValidationState } from '../ValidationContext'
import { FormFieldState } from '../State/Element'
// ------------------------------------------------
//const _assign = Object.assign

export class DataSchemaElement
{
	path = ''
	name = ''
	label = ''
	type = ''
	placeholder? : string
	default = ''
	disabled :boolean = false
	isPrimary = false

	schema?: any //DataSchema

	required = false
	visible = true

	options? : Array<any> = []

	validator?: (el :FormFieldState, state? :FieldValidationState) => Promise<any>

	// ---

	constructor(spec :any, parent? :DataSchemaElement)
	{
		//@ts-ignore
		this[DATA_SCHEMA_ELEMENT] = true

		invariant(_isPlainObject(spec), 'spec should be a plain object')
		
		invariant(!parent || isStructure(parent) || isSequence(parent),
			'parent should be falsey, Scehma Structure or Schema Sequence. Got ' + varType(parent))

		if(parent)
			this.schema = parent

		const fields = [
			'path',
			'name',
			'label',
			'type',
			'placeholder',
			'default',
			'disabled',
			'isPrimary',
			'required',
			'visible',
			'validator',
			'options'
		]
		fields.forEach((n) =>
			{
				if(n in spec)
				{
					//@ts-ignore
					this[n] = spec[n]
				}
			})

		if (!this.path)
			this.path = _compact([this.schema && this.schema.path, this.name]).join('.')
	}

	dispose()
	{
		//throw 'nothing should be disposing DataSchemaElement!';
		this.schema = undefined
	}

	visit(visitor :(el: DataSchemaElement) => void)
	{
		visitor(this)
	}
}
