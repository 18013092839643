import React, { useState, useEffect, DependencyList, useMemo, useSyncExternalStore } from 'react'
import { autorun, transaction } from 'mobx'
import {
    cleanState, createValidationContextStore,
    ValidationContext, ValidationContextState,
    ValidatorType
} from '../library/rathe-strongly-abstract/Form/ValidationContext'
import { FormFieldState } from "../library/rathe-strongly-abstract/Form/State/Element"
import { DataSchemaElement } from "../library/rathe-strongly-abstract/Form/Schema/Element"
import _isArray from 'lodash/isArray'
import { isFunction } from 'lodash'

export type SelectSchemaOptionsType = Array<{key?: string, text: string, value: string}>

export interface SchemaElementType
{
    path? : string
    name : string
    label? : string|boolean
    placeholder?: string|boolean
    type?: string|boolean
    options?: SelectSchemaOptionsType
    validator? : ValidatorType
    required? : string|boolean
    default? : string // this is used for Select fields only?
    initial? : string
}

export type SchemaElementListType = Array<SchemaElementType>
export type FormFieldStateListType = Array<FormFieldState>

export type SchemaFieldWatcherType = (
        states : Array<FormFieldState>,
        onChange : (key:string, value:string|number) => void,
        disposers? : Array<Function>
) => any

export function createFieldStates(
    schema : Array<SchemaElementType>,
    autorun : Function = () => {},
    disposers : Array<Function> = []) : Array<FormFieldState>
{
    const fieldStates: Array<any> = []

    for(let se of schema)
    {
        let state = new FormFieldState(new DataSchemaElement(se), se.initial)
        fieldStates.push(state)
    }

    return fieldStates
}

export type SchemaGetter = () => SchemaElementListType

export function useFormFieldStates(
    schema :SchemaElementListType|SchemaGetter,
    dependencies : DependencyList = []
) : FormFieldStateListType
{
    const fieldStates = useMemo<FormFieldStateListType>(() =>
    {
        return createFieldStates(
            isFunction(schema) ? schema() : schema,
            undefined)
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...dependencies])
    
    return fieldStates
}

export function useValidationContext(fields :FormFieldStateListType)
    :{context: ValidationContext, state: ValidationContextState, fields :FormFieldStateListType}
{
    //const store = useMemo(() => createValidationContextStore(fields), [fields])
    //const state = useSyncExternalStore<ValidationContextState>(store.subscribe, () => store.getState())
    //return {context: store.context, state}
    
    // Memoize validation context so we only have one instance, and it's regenerated if fields change
    const context = useMemo<ValidationContext>(() => new ValidationContext(fields), [fields])
    // Create a settable state, so we can trigger a render from
    const [state, setState] = useState<ValidationContextState>(context.state)
    // Update state (and re-render) when context state changes
    context.onStateChanged(setState)

    return {context, state, fields}
}

export function mapFieldStateList(list :Array<FormFieldState>)
{
    const obj :any = {}
    
    for(let f of list)
        obj[f.key] = f
    
    return obj
}

// This can't always be used because it ALWAYS triggers before the data is initialized for some reason

export const schemaValueWatcher : SchemaFieldWatcherType = (states, onValidChange, disposers) =>
{
    disposers = disposers && _isArray(disposers)
        ? disposers
        : []

    // console.warn('schemaValueWatcher states', states)
	
	const disposer = autorun(() =>
	{
		// console.warn('schemaValueWatcher autorun')

        transaction(() =>
        {
            let state : FormFieldState
            
            for(state of states)
            {
                if(!state.valid)
                    continue
                    
                let key = state.key || ''
                let value = state.value || ''
                
                onValidChange(key, value)
            }
        })
	})

    // @ts-ignore fuck you, Typescript
	disposers.push(disposer)

    // @ts-ignore fuck you again, Typescript
	return () => disposers.forEach(d => d())
}

