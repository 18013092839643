import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { apiRequestLogin, apiRequestLogout } from '../api'
import { isString } from 'lodash'

export type UserStateType = {
	loggedIn : boolean
	email : string
	role : string
	
	token? :string
	loginError?: string
	logoutReason?: string
}

// Define the initial state using that type
let initialState: UserStateType = {
	loggedIn: false,
	email: '',
	role: ''
}

const USER_STORAGE_KEY = 'user'

const savedState = JSON.parse(window.sessionStorage.getItem(USER_STORAGE_KEY) || 'null') as UserStateType

const userSlice = createSlice(
	{
		name: 'user',
		initialState: savedState || initialState,
		reducers: {
			setLoginError: (state, action: PayloadAction<string>) =>
            {
				state.loginError = action.payload
			},
			setSessionState: (state, action: PayloadAction<UserStateType>) =>
            {
				const user = action.payload
				state.loggedIn = user.loggedIn
				state.email = user.email
				state.role = user.role
				state.token = user.token
				
				state.loginError = undefined
				state.logoutReason = undefined
				
				window.sessionStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user))
			},
			setLoggedOut: (state, action?: PayloadAction<string>) =>
            {
				Object.assign(state, initialState)
				window.sessionStorage.clear()
				
				state.logoutReason = action
					? action.payload
					: undefined
			},
		},
	})

// Action creators are generated for each case reducer function
export const { setLoginError, setSessionState, setLoggedOut } = userSlice.actions
export const userReducer = userSlice.reducer
export const selectUserState = (state: RootState) => state.user

export function requestLogin(email : string, password : string)
{
	console.info('Requesting login', email)
	
	return async function requestLoginThunk(dispatch : Dispatch, getState : any)
	{
		try
		{
			const response = await apiRequestLogin({email, password})
			
			if(response.error)
			{
				dispatch(setLoginError('Invalid login'))
				return
			}
			
			dispatch(setSessionState({
				loggedIn: true,
				email: response.user.email,
				role: response.user.role,
				token: response.token
			}))
		}
		catch(e)
		{
			let errorMessage = 'Unexpected error'
			
			if(e instanceof Error)
				errorMessage = e.message
			else if(isString(e))
				errorMessage = e
			
			dispatch(setLoginError(errorMessage))
		}
	}
}

export function requestLogout()
{
	console.info('Requesting logout')
	
	return async function requestLogoutThunk(dispatch : Dispatch, getState : any)
	{
		await apiRequestLogout()
		dispatch(setLoggedOut("User requested logout"))
	}
}
