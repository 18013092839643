import { apiUrl } from '../../../config'
import { UserStateType } from '../../../store/user'
import { ProxyConfigList } from '../../../store/proxy'

export async function apiRequestCommand(user :UserStateType, command :string, vars? :any) :Promise<ProxyConfigList>
{
	console.info('apiRequestCommand')
	
	if(!user.token)
		throw new Error('User authorization required for request')
	
	const opts :{
		method :string
		headers :any
		body? :string
	} = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${user.token}`
		}
	}
	
	if(vars)
		opts.body = JSON.stringify(vars)
	
	const result = await fetch(`${apiUrl}/proxy/command/${command}`, opts)
	
	const data = await result.json()
	
	console.log('apiRequestCommand response data', data)
	
	if(data.error)
		throw new Error(data.error)
	
	return await data as ProxyConfigList
}
