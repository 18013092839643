import { Fragment } from 'react'
// ---
import { Header } from './components/Header'
import { Layout } from './components/Layout'
import { Loading } from './components/Loading'
import { ProxyListComponent } from './components/ProxyList'
import { NotificationList } from './components/Notification'

export function HomePage()
{
	return (
		<Fragment>
			<Header />
			<NotificationList />
			<Layout>
				<ProxyListComponent />
			</Layout>
			<Loading />
		</Fragment>
	)
}
