import _isString from 'lodash/isString'
import { FormFieldState } from '../State/Element'
import { FieldValidationState } from '../ValidationContext'

var re = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/

export async function domain(el :FormFieldState, state :FieldValidationState)
{
	//console.info('validating domain', `Name '${el.ndomain.tsame}'`, `Value '${el.value}'`, state);

	if (!el.visited)
		return state

	if (!re.test(el.value))
	{
		console.warn('domain test failed')

		state.valid = false
		state.message = (!el.value || el.value.length < 1) && el.schema && el.schema.required
			? _isString(el.schema.required)
				? el.schema.label + ' is required'
				: 'This field is required'
			: 'Invalid email address'
		state.errors.push({message: 'Invalid domain'})
	}

	return state
}
