
export function gMatchNameInArray(
        arr : Array<any>,
        getter? : (name : string) => string
    ) : (name : string) => boolean
{
    return name =>
    {
        for(let str of arr)
            if(str === (getter ? getter(name) : name))
                return true

        return false
    }
}

export function ucFirst(subject: string)
{
	return subject.charAt(0).toUpperCase() + subject.slice(1).toLowerCase()
}
