import { useNavigate, Link } from 'react-router-dom'
// ---
import { useAppDispatch, useAppSelector } from '../../store/store'
import { requestLogout, selectUserState } from '../../store/user'
import { publicRootPath } from '../../config'
import logo from "../../assets/images/ziplines-ed-logo-white.svg"
import { StatusButton } from './Header/StatusButton'

export function Header()
{
	const navigate = useNavigate()
	const user = useAppSelector(selectUserState)
	const loggedIn = user.loggedIn
	const dispatch = useAppDispatch()
	
	const onLogout = (e : any) =>
	{
		e.preventDefault()
		
		if(!loggedIn)
			return
		
		dispatch(requestLogout())
	}
	
	return (
		<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
			<div className="container-fluid">
				<Link className="navbar-brand col-md-2 col-lg-2 me-0 px-3 fs-6 bg-transparent shadow-none" to={publicRootPath} >
					<img src={logo} width="115" alt="logo" /> Proxy Admin
				</Link>
				<button className="navbar-toggler"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					
					<ul className="navbar-nav me-auto mb-2 mb-lg-0">
						<li className="nav-item">
						
						</li>
					</ul>
					
					<div className="d-flex">
						<ul className="navbar-nav nav-pills nav-pills me-auto mb-2 mb-lg-0">
							<li className="nav-item">
								<StatusButton />
							</li>
							<li className="nav-item">
								{loggedIn
									?
									<a onClick={onLogout}
									   className="btn btn-danger nav-link px-3">Sign out</a>
									:
									<span>Not Logged In</span>
								}
							</li>
						</ul>
					</div>
					
				</div>
			</div>
		</nav>
	)
}

/*
<Link className="navbar-brand col-md-2 col-lg-2 me-0 px-3 fs-6 bg-transparent shadow-none" to={publicRootPath} >
					<img src={logo} width="115" alt="logo" /> Proxy Admin
				</Link>
				
				<StatusButton />
				
{loggedIn
									?
									<a onClick={onLogout} className="btn btn-danger nav-link px-3" href="src/pages/components/Header#">Sign out</a>
									:
									<span>Not Logged In</span>
								}
								*/
