// This makes some kind of entity manager
import { createEntityAdapter } from '@reduxjs/toolkit'
import { NotificationEntity } from './types'

export const notificationAdapter = createEntityAdapter<NotificationEntity>(
{
	// Assume IDs are stored in a field other than `entity.id`
	selectId: (proxy) => proxy.id,
	// Keep the "all IDs" array sorted based on domain
	sortComparer: (a, b) =>
		(a.time - b.time),
})
