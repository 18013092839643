import { Fragment, MouseEventHandler, useEffect, useMemo, useState } from 'react'
// ---
import './Login/Login.scss'
import logo from "../assets/images/ziplines-ed-logo-purple.svg"
import { Loading } from './components/Loading'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../store/store'
import { requestLogin, selectUserState } from '../store/user'
import { selectViewState } from '../store/view'
import { SchemaElementListType, useFormFieldStates, useValidationContext } from '../store/schema'
import { email as emailValidator } from '../library/rathe-strongly-abstract/Form/validators'
import { SchemaInput, SchemaLabel, SchemaError } from './components/forms/SchemaField'

export function LoginPage()
{
	useEnsureNotLoggedIn()
	
	const view = useAppSelector(selectViewState)
	const user = useAppSelector(selectUserState)
	const dispatch = useAppDispatch()
	
	// Disable form submission when view is in loading state
	const disabled = view.loading
	const logoutReason = user.logoutReason
	const loginError = user.loginError
	
	const [email, password] = useFormFieldStates(() => [
		{
			type: 'email',
			name: 'email',
			label: 'Email',
			placeholder: 'name@example.com',
			required: true,
			validator: emailValidator
		},
		{
			type: 'password',
			name: 'password',
			label: 'Password',
			placeholder: 'Password',
			required: true
		}
	] as SchemaElementListType)
	
	const {context, state} = useValidationContext([email, password])
	
	const onSubmit = async (e : any) =>
	{
		e.preventDefault()
		
		if(disabled)
			return
		
		if(await context.validate())
			dispatch(requestLogin(email.value, password.value))
	}
	
	//console.info('Login render', state.validated, state.valid)
	
	return (
		<Fragment>
			<main className="form-signin w-100 m-auto text-center">
				
				<form>
					<img className="mb-4" src={logo} width="200" alt="logo" />
					<div className="form-floating">
						<SchemaInput state={email} />
						<SchemaLabel state={email} />
						<SchemaError state={email} />
					</div>
					<div className="form-floating">
						<SchemaInput state={password} />
						<SchemaLabel state={password} />
						<SchemaError state={password} />
					</div>
					
					<button
						disabled={disabled}
						onClick={onSubmit}
						className="w-100 btn btn-lg btn-primary"
						type="submit">
						Sign in
					</button>
					
					{state.validated && !state.valid
						?
						<div className="error pt-4">
							<em>Please fix errors above</em>
						</div>
						:
						logoutReason &&
							<div className="pt-4">
								<em>* {logoutReason}</em>
							</div>
					}
					{loginError &&
						<div className="pt-4">
							<em>* {loginError}</em>
						</div>
					}
				</form>
			</main>
			<Loading />
		</Fragment>
	)
}

function useEnsureNotLoggedIn()
{
	const location = useLocation()
	const [searchParams, setSearchParams] = useSearchParams()
	const navigate = useNavigate()
	const user = useAppSelector(selectUserState)
	
	useEffect(() =>
	{
		if(user.loggedIn)
		{
			console.warn('user is currently logged in - navigating to home')
			const path = searchParams.get('l')
			navigate(path || '/')
		}
		
	}, [navigate, location, searchParams, user])
}

