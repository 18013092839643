import { apiUrl } from '../../config'
import { UserStateType } from '../../store/user'
import { ProxyConfigList, ProxyConfig, ProxyConfigEntity } from '../../store/proxy'

export async function apiRequestProxies(user : UserStateType) : Promise<ProxyConfigList>
{
	if(!user.token)
	{
		console.warn(user)
		throw new Error('User authorization required for request')
	}
	
	const result = await fetch(`${apiUrl}/proxy`, {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${user.token}`
		}
	})
	
	const data = await result.json()
	
	console.log('apiRequestProxies response data', data)
	
	if(data.error)
		throw new Error(data.error)
	
	return await data as ProxyConfigList
}

export async function apiRequestProxyItem(user :UserStateType, id :string) : Promise<ProxyConfig>
{
	if(!user.token)
		throw new Error('User authorization required for request')
	
	console.warn('apiRequestProxyItem', id, 'loggedIn', user.loggedIn)
	
	const result = await fetch(`${apiUrl}/proxy/${id}`, {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${user.token}`
		}
	})
	
	const data = await result.json()
	
	console.log('apiRequestProxies response data', data)
	
	if(data.error)
		throw new Error(data.error)
	
	return await data as ProxyConfig
}

export async function apiRequestCreateProxy(user :UserStateType, proxy :ProxyConfig) : Promise<ProxyConfig>
{
	return _apiRequestWriteProxy({ method: 'POST', user, proxy })
}

export async function apiRequestUpdateProxy(user :UserStateType, proxy :ProxyConfig) : Promise<ProxyConfig>
{
	return _apiRequestWriteProxy({ method: 'PUT', user, proxy })
}

export async function apiRequestDeleteProxy(user :UserStateType, id: string) : Promise<ProxyConfig>
{
	if(!user.token)
		throw new Error('User authorization required for request')
	
	const tail = '/'+id
	
	const result = await fetch(`${apiUrl}/proxy${tail}`, {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${user.token}`
		}
	})
	
	const data = await result.json()
	
	console.log('apiRequestCreateProxy response data', data)
	
	if(data.error)
		throw new Error(data.error)
	
	return await data as ProxyConfig
}

// ---

async function _apiRequestWriteProxy({ method, user, proxy } : { method: string, user :UserStateType, proxy :ProxyConfig }) : Promise<ProxyConfig>
{
	if(!user.token)
		throw new Error('User authorization required for request')
	
	const id = proxy.id
	const tail = proxy.id ? '/'+proxy.id : ''
	
	delete proxy.id
	
	const result = await fetch(`${apiUrl}/proxy${tail}`, {
		method,
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${user.token}`
		},
		body: JSON.stringify(proxy)
	})
	
	const data = await result.json()
	
	console.log('apiRequestCreateProxy response data', data)
	
	if(data.error)
		throw new Error(data.error)
	
	return await data as ProxyConfig
}
