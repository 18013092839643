import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { apiRequestLogin, apiRequestLogout } from '../api'
import { isString } from 'lodash'
import { UserStateType } from './user'

export type AdminStateType = {
	users: Array<UserType>
}

// Define the initial state using that type
let initialState: AdminStateType = {
	users: []
}

const adminSlice = createSlice(
	{
		name: 'admin',
		initialState: initialState,
		reducers: {
			setSomething: (state, action: PayloadAction<string>) =>
            {
			},
		},
	})

// Action creators are generated for each case reducer function
export const { setSomething } = adminSlice.actions
export const adminReducer = adminSlice.reducer
export const selectAdminState = (state: RootState) => state.admin

export type UserType = {
	id :string
	email :string
	role :string
}
