import { Location, Action, createBrowserHistory } from 'history'

export const history = createBrowserHistory()

export type NavStateChangeHandlerType = (location : Location, action : Action) => {}
export type NavStateDataType = {
	location : Location,
	action : Action
}

export function onNavStateChange(cb? : NavStateChangeHandlerType)
{
	history.listen(({location, action}) =>
	{
		// this is called whenever new locations come in
		// the action is POP, PUSH, or REPLACE
		
		console.info(action, 'at location:', location)
		
		if(cb)
			cb(location, action)
	})
}
