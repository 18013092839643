import React, { Fragment, ReactElement, ReactNode, useRef, useState } from 'react'
import {
	Card, CardBody, CardHeader,
	DropdownItem, Panel, PanelHeader, PanelMain,
	Tooltip
} from '@patternfly/react-core'
import { Button } from 'reactstrap'
import ArrowIcon from '@patternfly/react-icons/dist/esm/icons/arrow-icon'

type ConfirmationProps =
	{
		key?: string|number
		prompt?: string,
		description?: string,
		children?: ReactNode,
		controls?: ReactElement<any>,
		onResult? :(confirmed :boolean) => any
		onConfirm? :Function
		onReject? :Function
		icon?: ReactNode
	}

export function ConfirmDropdownItem(props : ConfirmationProps)
{
	const key = props.key
	const children = props.children
	let controls = props.controls
	const prompt = props.prompt
	const desc = props.description
	const icon = props.icon
	
	const ref = useRef(null)
	const [open, setOpen] = useState<boolean>(false)
	
	const resultHandler = (confirmed :boolean) =>
	{
		setOpen(false)
		
		if(props.onResult)
			props.onResult(confirmed)
		
		if(confirmed && props.onConfirm)
			props.onConfirm()
	}
	
	if(!controls)
		controls = <>
			<Button color="success"
					onClick={() => resultHandler(true)}>
				Confirm
			</Button>{' '}
			<Button color="danger"
					onClick={() => resultHandler(false)}>
				Cancel
			</Button>
		</>
	
	// ms-sm-auto
	return (
		<>
			<Tooltip
				isVisible={open}
				trigger='click'
				content={
					<Card isPlain>
						<CardHeader>
							<h6>{prompt || (desc && `Are you sure you want to ${desc}?`) || "Are you sure?"}</h6>
						</CardHeader>
						<CardBody>
							{controls}
						</CardBody>
					</Card>
				}
				entryDelay={1}
				position={'left'}
				enableFlip={true}
				reference={ref}
			/>
			<DropdownItem
				key={key}
				icon={icon}
				description={desc}
				component={
					<a data-ouia-component-type="PF4/DropdownItem"
					   aria-disabled="false"
					   className="pf-m-icon pf-c-dropdown__menu-item pf-m-description text-decoration-none"
					   role="menuitem"
					   ref={ref}
					   onClick={() => setOpen(true)}
					>
						<div className="pf-c-dropdown__menu-item-main">
							{icon &&
								<span className="pf-c-dropdown__menu-item-icon">{icon}</span>
							}
							{children}
						</div>
						<div className="pf-c-dropdown__menu-item-description">
							{desc}
						</div>
					</a>
				}
			/>
		</>
	)
}
