import { Fragment, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
	Card,
	CardTitle,
	CardBody,
	CardFooter,
	SplitItem,
	Split,
	CardHeader,
	Divider,
	Flex,
	FlexItem,
	Tabs, Tab, TabTitleText
} from '@patternfly/react-core'
import SettingsIcon from '@patternfly/react-icons/dist/esm/icons/cog-icon'
import CodeIcon from '@patternfly/react-icons/dist/esm/icons/code-icon'
import {
	FormGroup,
	Col, Button, ButtonGroup
} from 'reactstrap'
import CodeMirror from '@uiw/react-codemirror'

// ---
import { Header } from './Header'
import { Layout } from './Layout'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { selectUserState } from '../../store/user'
import {
	ProxyConfigEntity,
	ProxyConfigMappingEntity, removeProxyMapping, selectProxyMappingsByDomain,
	selectByDomain, updateSingleProxy,
} from '../../store/proxy'
import { SchemaElementListType, useFormFieldStates, useValidationContext } from '../../store/schema'
import { SchemaError, SchemaInput, SchemaLabel, SchemaSelectStatus } from './forms/SchemaField'
import { fetchProxyConfigById, fetchProxyConfigs, updateProxyConfig } from '../../store/proxy/crud'
import { commandProcessSingle } from '../../store/proxy/command'
import { selectRemoteState } from '../../store/remote'
import { proxyEntityToConfig } from '../../store/proxy/adapter'
import { ProxyMappingList } from './EditProxy/ProxyMappingList'
import ArrowIcon from '@patternfly/react-icons/dist/esm/icons/arrow-icon'
import ClockIcon from '@patternfly/react-icons/dist/esm/icons/clock-icon'
import CheckCircleIcon from '@patternfly/react-icons/dist/esm/icons/check-circle-icon'
import BanIcon from '@patternfly/react-icons/dist/esm/icons/ban-icon'
import { onboardingUri } from '../../config'

export function EditProxyComponent() {
	//console.warn('render EditProxy')

	const {
		requestedDomain,
		remote,
		proxy,
		mappings,
		name,
		status,
		context,
		formState,
		disabled,
		tab, setTab,
		//structuredText,
		//setStructuredText,
		//configToStructuredText,
		//structuredTextToConfig,
		onSave,
		onReload,
		onApply,
	} = useInitEditProxy()

	if (!proxy)
		return (
			<Layout>
				<div>A proxy for <code>{requestedDomain}</code> is not known.</div>
			</Layout>
		)

	return (
		<Card isRounded>
			<CardHeader>
				<Split hasGutter className="full-width">
					<SplitItem>
						<CardTitle>
							<h4>[ <code>{proxy.domain}</code> ]</h4>
						</CardTitle>
					</SplitItem>
					<SplitItem isFilled></SplitItem>
					<SplitItem>
						<ButtonGroup>
							<button
								{...{ disabled, onClick: onReload }}
								className="btn btn-sm btn-outline-secondary">
								<i className="bi bi-arrow-clockwise" /> Reload
							</button>
							{!remote.active &&
								<button {...{ disabled, onClick: onApply }}
									className="btn btn-sm btn-outline-danger">
									<i className="bi bi-exclamation-triangle-fill"></i> Save and Apply
								</button>
							}
						</ButtonGroup>
					</SplitItem>
				</Split>
			</CardHeader>
			<CardBody>

				<FormGroup row>
					<SchemaLabel state={name} />
					<Col>
						<SchemaInput state={name} />
						<SchemaError state={name} />
					</Col>
				</FormGroup>

				<FormGroup row>
					<Flex>
						<FlexItem>
							<SchemaLabel state={status} />&nbsp; : &nbsp;
							<SchemaSelectStatus state={status} />
						</FlexItem>
						<Divider orientation={{
							default: 'vertical'
						}} />
						<FlexItem>
							<a href={`${onboardingUri}/${proxy.id}`}
								target={proxy.domain}>
								<ArrowIcon />{' '}
								Onboarding Status Page
							</a>
						</FlexItem>
					</Flex>
				</FormGroup>

				<Divider />

				<FormGroup row>
					<label>Enable Blog</label>&nbsp; : &nbsp;
				</FormGroup>

				<Divider />

				<ProxyMappingList proxy={proxy} />

				{/*<Tabs*/}
				{/*	activeKey={tab}*/}
				{/*	onSelect={(e, key) =>*/}
				{/*	{*/}
				{/*		if(key === 0)*/}
				{/*			setStructuredText(configToStructuredText())*/}
				{/*		else*/}
				{/*			structuredTextToConfig(structuredText)*/}
				{/*		*/}
				{/*		setTab(key)*/}
				{/*	}}*/}
				{/*	isBox={true}*/}
				{/*>*/}
				{/*	<Tab eventKey={0}*/}
				{/*		 title={<TabTitleText><SettingsIcon /> Configured paths</TabTitleText>}>*/}
				{/*		<ProxyMappingList proxy={proxy} />*/}
				{/*	</Tab>*/}
				{/*	<Tab eventKey={1}*/}
				{/*		 title={<TabTitleText><CodeIcon /> Plaintext</TabTitleText>}>*/}
				{/*		<CodeMirror*/}
				{/*			value={structuredText}*/}
				{/*			height="400px"*/}
				{/*			onChange={(value, viewUpdate) =>*/}
				{/*				setStructuredText(value)}*/}
				{/*		/>*/}
				{/*	</Tab>*/}
				{/*</Tabs>*/}

			</CardBody>
			<CardFooter>
				{proxy.modified || formState.modified
					?
					<button {...{ disabled, onClick: onSave }} type="button" className="btn btn-sm btn-primary me-2">
						Save Changes
					</button>
					:
					null
				}
			</CardFooter>
		</Card>
	)
}

function useInitEditProxy() {
	const user = useAppSelector(selectUserState)
	const remote = useAppSelector(selectRemoteState)
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const routeParams = useParams()
	let requestedDomain: string = routeParams.domain as string

	let [urlSearchParams] = useSearchParams()
	let [disabled, setDisabled] = useState(false)

	let proxy = useAppSelector(selectByDomain(requestedDomain))
	let mappings = useAppSelector(selectProxyMappingsByDomain(requestedDomain))

	let [tab, setTab] = useState<string | number>(0)

	const configToStructuredText = () => {
		console.info('configToStructuredText')

		let parts = []

		for (let m of mappings)
			parts.push(`${m.from}\t${m.to}`)

		return parts.join("\n")
	}

	//let structuredText = useMemo<string>(() => configToStructuredText(), [mappings])
	//let [f, setStructuredText] = useState(structuredText)
	//
	//const structuredTextToConfig = (text :string) =>
	//{
	//
	//}

	const fields = useFormFieldStates(() => [
		{
			name: 'name',
			label: 'Partner Name',
			type: 'text',
			placeholder: 'Foo Partner',
			required: true,
			initial: proxy && proxy.name
		},
		{
			name: 'status',
			label: 'Proxy Status',
			options: [
				{
					title: 'Pending',
					key: 'pending',
					tooltip: 'Proxy configuration has been created and is awaiting DNS configuration',
					icon: <ClockIcon />,
					className: 'option-variant'
				},
				{
					title: 'Online',
					key: 'online',
					tooltip: 'Proxy is live and fully operational',
					icon: <CheckCircleIcon />,
					className: 'option-variant'
				},
				{
					title: 'Disabled',
					key: 'disabled',
					tooltip: 'Proxy configuration has been disabled',
					icon: <BanIcon />,
					className: 'option-variant'
				},
			],
			required: true,
			initial: proxy && proxy.status
		}
	] as SchemaElementListType, [proxy])

	console.info('proxy && proxy.status', proxy && proxy.status)

	const { context, state: formState } = useValidationContext(fields)
	const [name, status] = fields

	const constuctConfig = (entity: ProxyConfigEntity) => {
		let proxy = proxyEntityToConfig(entity)

		proxy.name = name.value
		proxy.status = status.value

		return proxy
	}

	const onReload = async (e: any) => {
		e.preventDefault()

		if (disabled)
			return

		if (!proxy?.id)
			return

		setDisabled(true)

		await fetchProxyConfigById(dispatch, user, proxy.id)

		setDisabled(false)
	}

	const onSave = async (e: any) => {
		e.preventDefault()

		if (disabled)
			return

		if (proxy) {
			setDisabled(true)

			//let newProxy =
			await updateProxyConfig(dispatch, user, constuctConfig(proxy))
			//navigate(`/proxy/${newProxy.domain}`)

			setDisabled(false)
		}
	}

	const onApply = async (e: any) => {
		e.preventDefault()

		if (disabled)
			return

		if (proxy) {
			setDisabled(true)

			await updateProxyConfig(dispatch, user, constuctConfig(proxy))
			//let proxyConfig = constuctConfig(proxy)
			//let newProxy = await updateProxyConfig(dispatch, user, constuctConfig(proxy))
			await commandProcessSingle(dispatch, user, proxy.id!)
			navigate('/remote')

			setDisabled(false)
		}
	}

	return {
		user,
		remote,
		requestedDomain,
		proxy,
		mappings,
		disabled,
		name,
		status,
		context, formState,
		tab, setTab,
		//structuredText,
		//setStructuredText,
		//configToStructuredText,
		//structuredTextToConfig,
		onSave,
		onReload,
		onApply,
		dispatch
	}
}
